import React from "react";
import EditIcon from "../Icons/EditIcon";
import CrossIcon from "../Icons/CrossIcon";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import AvatarUpload from "../LifestyleSetup/components/LifestyleModals/components/ProfileModal/components/AvatarUpload";
import { useState, useEffect } from "react";
import axios from "axios";
import { useStore } from "../../utils/store";
import classNames from "classnames";

function ProfileChange({ setContentType }) {
  const genderOptions = ["female", "male", "diverse", "none"];
  const genderOptionsLabel = [
    "Weiblich",
    "Männlich",
    "Diverse",
    "Keine Angaben",
  ];

  const dietOptions = ["basic", "pro"];
  const dietOptionsLabel = ["BASIC", "PRO"];

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    return new Date(dateString).toISOString().split("T")[0];
  };

  const [user, setUser] = useStore((state) => [state.user, state.setUser]);
  const [bday, setBday] = useState(formatDate(user.bday));
  const [gender, setGender] = useState(user.gender);
  const [diet, setDiet] = useState(
    dietOptionsLabel.includes(user.diet) ? user.diet : "basic"
  );
  const [email, setEmail] = useState(user.email);
  const [password, setPassword] = useState("");
  const [passwordRepeat, setPasswordRepeat] = useState("");
  const [avatarSet, setAvatarSet] = useState(true);

  useEffect(() => {
    if (user.avatar) {
      setAvatarSet(true);
    }
  }, [user.avatar]);

  const handleSubmit = (e) => {
    e.preventDefault();

    let updateData = {};

    if (
      password !== "" &&
      passwordRepeat !== "" &&
      password === passwordRepeat
    ) {
      updateData = {
        avatar: user.avatar,
        email: email,
        registered: user.email,
        password: password,
        gender: gender,
        bday: bday,
        diet: diet,
      };
    } else {
      updateData = {
        avatar: user.avatar,
        email: email,
        registered: user.email,
        gender: gender,
        bday: bday,
        diet: diet,
      };
    }

    axios
      .post(
        `${
          process.env.NODE_ENV === "production"
            ? "api"
            : "http://localhost:5001/api"
        }/register/change/profile`,
        {
          ...updateData,
        }
      )
      .then((response) => {
        if (password !== "") {
          toast.success("Profil erfolgreich aktualisiert.");
        } else {
          toast.success("Profil erfolgreich aktualisiert.");
        }
        setPassword("");
        setPasswordRepeat("");
        setUser({
          ...user,
          gender: updateData.gender,
          email: updateData.email,
          bday: updateData.bday,
          diet: updateData.diet,
        });
      })
      .catch((error) => {
        // This block will be executed if the axios call throws an error
        console.error(error);
        toast.error("An error occurred while updating the profile.");
      });
  };

  return (
    <>
      {avatarSet ? (
        <div className="relative mx-auto mb-3 max-w-[144px]">
          <img
            src={user.avatar}
            alt=""
            className="rounded-full border-2 aspect-square w-full h-full bg-white"
          />
          <div
            className="absolute top-0 right-0"
            onClick={() => setAvatarSet(false)}
          >
            <EditIcon />
          </div>
        </div>
      ) : (
        <div className="relative mx-auto mb-3 max-w-[144px]">
          <AvatarUpload
            user={user}
            setUser={setUser}
            setAvatarSet={setAvatarSet}
          />
          <div
            className="absolute top-0 right-0"
            onClick={() => setAvatarSet(true)}
          >
            <CrossIcon />
          </div>
        </div>
      )}

      <form onSubmit={(e) => handleSubmit(e)}>
        <div className="mb-3">
          <label htmlFor="email" className="text-sm">
            Vor- & Nachname
          </label>

          <div className="relative mt-2">
            <input
              type="text"
              className="w-full rounded-lg p-4 text-sm shadow-sm border border-slate-200 text-gray-400 bg-white"
              placeholder="Vor- & Nachname"
              disabled
              value={user.firstname + " " + user.lastname}
              readOnly
            />
          </div>

          <p className="text-xs mt-2">
            Um deinen Namen zu ändern, kontaktiere uns bitte direkt.
          </p>
        </div>

        <div className="mb-3">
          <label htmlFor="email" className="text-sm">
            Emailadresse
          </label>

          <div className="relative mt-2">
            <input
              type="email"
              className="w-full rounded-lg p-4 text-sm shadow-sm border border-slate-200 text-gray-400 bg-white"
              placeholder="Emailadresse"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              disabled
              readOnly
            />
          </div>
        </div>

        <div className="mb-3">
          <label htmlFor="email" className="text-sm">
            Geburtsdatum:
          </label>

          <div className="relative mt-2">
            <input
              type="date"
              className="w-full rounded-lg p-4 text-sm shadow-sm border border-slate-300 appearance-none bg-white"
              placeholder={bday}
              value={bday}
              onChange={(e) => setBday(e.target.value)}
            />
          </div>
        </div>

        <div className="mb-3">
          <label htmlFor="gender" className="text-sm">
            Ernährungsregeln:
          </label>
          <div className="relative mt-2">
            <select
              name="gender"
              id="gender"
              defaultValue={diet}
              className="w-full rounded-lg p-4 text-sm shadow-sm border border-slate-300 appearance-none bg-white"
              onChange={(e) => {
                setDiet(e.target.value);
              }}
            >
              {dietOptions.map((entrie, index) => (
                <option key={index} value={entrie}>
                  {dietOptionsLabel[index]}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="mb-3">
          <label htmlFor="gender" className="text-sm">
            Geschlecht:
          </label>
          <div className="relative mt-2">
            <select
              name="gender"
              id="gender"
              defaultValue={gender}
              className="w-full rounded-lg p-4 text-sm shadow-sm border border-slate-300 appearance-none bg-white"
              onChange={(e) => {
                setGender(e.target.value);
              }}
            >
              {genderOptions.map((entrie, index) => (
                <option key={index} value={entrie}>
                  {genderOptionsLabel[index]}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="mb-3">
          <label htmlFor="email" className="text-sm">
            Passwort
          </label>

          <div className="relative mt-2">
            <input
              type="password"
              className="w-full rounded-lg p-4 text-sm shadow-sm border border-slate-300"
              placeholder="Passwort"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
        </div>

        <div className="relative mb-7">
          <label htmlFor="email" className="text-sm">
            Passwort Wiederholen
          </label>

          <div className="relative mt-2">
            <input
              type="password"
              className="w-full rounded-lg p-4 text-sm shadow-sm border border-slate-300"
              placeholder="Passwort Wiederholen"
              value={passwordRepeat}
              onChange={(e) => setPasswordRepeat(e.target.value)}
            />
          </div>
        </div>

        <button
          className={classNames(
            "bg-pink w-full block text-white font-extrabold p-4 rounded-full mb-4 transition-colors duration-300 ease-in-out",
            {
              "bg-slate-50 text-slate-200":
                email === user.email &&
                diet === user.diet &&
                bday === user.bday &&
                gender === user.gender &&
                ((password !== passwordRepeat && password === "") ||
                  passwordRepeat === ""),
            }
          )}
          type="submit"
          disabled={
            email === user.email &&
            diet === user.diet &&
            bday === user.bday &&
            gender === user.gender &&
            ((password !== passwordRepeat && password === "") ||
              passwordRepeat === "")
          }
        >
          ÄNDERUNGEN ÜBERNEHMEN
        </button>

        <button
          className="w-full block text-white font-extrabold text-gray-300"
          onClick={() => setContentType("menu")}
        >
          ABBRECHEN
        </button>
      </form>
      <ToastContainer />
    </>
  );
}

export default ProfileChange;
