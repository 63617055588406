import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { useStore } from "../../utils/store";
import HiLifeLogo from "../Icons/HiLifeLogo";
import "./AdminLogin.scss";

function AdminLogin() {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const setUser = useStore((state) => state.setUser);

  const handleLogin = (event) => {
    event.preventDefault();

    axios
      .post(
        `${
          process.env.NODE_ENV === "production"
            ? "/api"
            : "http://localhost:5001/api"
        }/login/signin/admin`,
        {
          email: username,
          password: password,
        }
      )
      .then((response) => {
        toast.remove();
        toast.success("Sie wurden erfolgreich eingelogged.");
        setUser(response.data);
        navigate("/admin/dashboard");
      })
      .catch((err) => {
        if (!err.response.data.isUsernameMatch)
          toast.error("Incorrect Username!");
        else if (!err.response.data.isVerified) toast.error("Not Verified!");
        else if (!err.response.data.isAdmin)
          toast.error("You are not an Admin!");
        else toast.error("Incorrect Password!");
      });
  };

  return (
    <div className="bg-beige w-full h-full flex items-center justify-center admin-login">
      <Toaster />
      <div className="max-w-md w-full p-5">
        <div className="flex justify-center mb-5 admin-login-logo">
          <HiLifeLogo />
        </div>
        <h1 className="text-2xl font-extrabold text-center mb-3">
          ADMIN LOGIN
        </h1>
        <form onSubmit={handleLogin}>
          <div className="mb-5">
            <label htmlFor="email" className="text-sm">
              E-Mail Adresse
            </label>

            <div className="relative mt-2">
              <input
                type="email"
                className="w-full rounded-lg p-4 text-sm shadow-sm border border-slate-300"
                placeholder="E-Mail Adresse"
                onChange={(e) => setUsername(e.target.value)}
                value={username}
              />
            </div>
          </div>

          <div className="mb-3">
            <label htmlFor="email" className="text-sm">
              Passwort
            </label>

            <div className="relative mt-2">
              <input
                type="password"
                className="w-full rounded-lg p-4 text-sm shadow-sm border border-slate-300"
                placeholder="Passwort"
                onChange={(e) => setPassword(e.target.value)}
                value={password}
              />
            </div>
          </div>

          <button
            className="bg-pink w-full block text-white font-extrabold p-4 rounded-full mb-8"
            type="submit"
          >
            ANMELDEN
          </button>
        </form>
      </div>
    </div>
  );
}

export default AdminLogin;
