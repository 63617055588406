import React, { useState } from "react";
import "./styles/breathing.scss";
import BreathIcon from "../../../../../Icons/BreathIcon";
import Stopwatch from "./components/Stopwatch";
import classNames from "classnames";

function BreathingModal({ setOpen, updateHabit }) {
  const [handleStart, setHandleStart] = useState(false);
  const [interv, setInterv] = useState();

  const [time, setTime] = useState({ s: 0, m: 5 });

  const start = () => {
    clearInterval(interv); // Clear any existing interval
    setTime({ s: 0, m: 5 }); // Reset to 5 minutes
    run();
    setInterv(setInterval(run, 1000));
  };

  const reset = () => {
    clearInterval(interv);
    setTime({ s: 0, m: 5 }); // Reset to 5 minutes
  };

  let updatedS = time.s,
    updatedM = time.m;

  const run = () => {
    if (updatedM === 0 && updatedS === 0) {
      clearInterval(interv);
      setHandleStart(false);
      return;
    }

    if (updatedS === 0) {
      updatedM--;
      updatedS = 59;
    } else {
      updatedS--;
    }
    return setTime({ s: updatedS, m: updatedM });
  };

  return (
    <div className="w-full breathing">
      <div className="w-8 w-8 mx-auto mb-4">
        <BreathIcon />
      </div>
      <h1 className="font-extrabold text-2xl text-center mb-6">ATMEN</h1>

      <div className="breathing-exercise">
        <div className="breathing-exercise-outer">
          <span
            className={`breathing-exercise-in ${
              handleStart ? "breathing-exercise-in-animation" : ""
            }`}
          >
            EINATMEN
          </span>
          <span
            className={`breathing-exercise-out ${
              handleStart ? "breathing-exercise-out-animation" : ""
            }`}
          >
            AUSATMEN
          </span>
          <span
            className={`breathing-exercise-center ${
              handleStart ? "breathing-exercise-center-animation" : ""
            }`}
          ></span>
        </div>
        <div className="flex w-full items-center mb-5 gap-4 mt-12">
          {!handleStart ? (
            <button
              className={classNames(
                "bg-green w-full block text-white font-extrabold p-4 rounded-full transition-colors duration-300 ease-in-out"
              )}
              onClick={() => {
                setHandleStart(true);
                start();
              }}
            >
              START
            </button>
          ) : (
            <>
              <button
                className={classNames(
                  "bg-pink flex-auto block text-white font-extrabold p-4 rounded-full transition-colors duration-300 ease-in-out"
                )}
                onClick={() => {
                  setHandleStart(false);
                  reset();
                }}
              >
                STOP
              </button>
              <div className="stopwatch">
                <Stopwatch time={time} />
              </div>
            </>
          )}
        </div>
      </div>

      <button
        className={classNames(
          "bg-pink w-full block text-white font-extrabold p-4 rounded-full transition-colors duration-300 ease-in-out"
        )}
        onClick={() => {
          updateHabit(2);
          setOpen(false);
          setHandleStart(false);
          reset();
        }}
      >
        FERTIG
      </button>

      <button
        className="w-full block text-white font-extrabold text-gray-300 mt-4"
        onClick={() => setOpen("keepup")}
      >
        ABBRECHEN
      </button>
    </div>
  );
}

export default BreathingModal;
