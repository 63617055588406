import React, { useEffect } from "react";
import classNames from "classnames";
import { useState } from "react";
import ProfileChange from "../ProfileChange/ProfileChange";
import SeeOrders from "../SeeOrders/SeeOrders";
import Score from "../Score";
import { useNavigate } from "react-router-dom";
import { differenceInDays } from "date-fns";
function Sidebar({
  isSidebarOpen,
  setIsSidebarOpen,
  user,
  setUser,
  userChallenge,
  challenge,
}) {
  const [lifestyleScore, setLifestyleScore] = useState(
    userChallenge.lifestyleScore
  );
  const [lifestyleScore2, setLifestyleScore2] = useState(
    userChallenge.lifestyleScore2
  );

  const [lifestyleTotal, setLifestyleTotal] = useState(0);
  const [lifestyleTotal2, setLifestyleTotal2] = useState(0);
  const [showDesktopMenu, setShowDesktopMenu] = useState();
  const navigate = useNavigate();
  const [contentType, setContentType] = useState("menu");
  const html = document.querySelector("html");
  if (isSidebarOpen) {
    html.style.overflow = "hidden";
  } else {
    html.style.overflow = null;
  }
  useEffect(() => {
    if (lifestyleScore) {
      let sum = 0;
      lifestyleScore.forEach((e) => {
        sum = sum + e;
      });
      setLifestyleTotal(sum);
    }

    if (lifestyleScore2) {
      let sum = 0;
      lifestyleScore2.forEach((e) => {
        sum = sum + e;
      });
      setLifestyleTotal2(sum);
    }
  }, [lifestyleScore, lifestyleScore2]);

  return (
    <>
      <div
        className={classNames(
          "fixed top-0 left-0 right-0 bottom-0 w-full h-full bg-gray-900 z-40 transition-opacity duration-300 ease-in-out",
          {
            "opacity-0 pointer-events-none": !isSidebarOpen,
            "opacity-90": isSidebarOpen,
          }
        )}
        onClick={() => {
          if (showDesktopMenu) {
            setShowDesktopMenu(false);
            setTimeout(() => {
              setIsSidebarOpen(false);
            }, 175);
          } else {
            setIsSidebarOpen(false);
          }
        }}
      ></div>
      <div
        className={classNames(
          "z-50 bg-beige lg:bg-[#E2E3D9] fixed left-0 top-0 h-full w-11/12 lg:w-full transition-all duration-300 ease-in-out -translate-x-full lg:rounded-tr-lg lg:rounded-br-lg lg:max-w-sm",
          {
            "translate-x-0": isSidebarOpen,
          }
        )}
      >
        <nav className="flex flex-col h-full lg:hidden">
          {contentType === "menu" && (
            <>
              <button
                onClick={() => setIsSidebarOpen(false)}
                className="px-6 pt-6 pb-2 mb-16"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-6 h-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
              <header className="px-6 pb-5 text-center lg:text-left">
                <h2 className="text-2xl font-extrabold">PROFIL</h2>
              </header>
              <ul className="px-6 text-center lg:text-left">
                <li
                  className="text-xl font-extrabold mb-7 text-pink cursor-pointer"
                  onClick={() => setContentType("personal-data")}
                >
                  PERSÖNLICHE DATEN
                </li>
                <li
                  className="text-xl font-extrabold text-pink mb-7 cursor-pointer"
                  onClick={() => setContentType("lifestyle-score")}
                >
                  LIFESTYLE SCORE
                </li>
                <li
                  className="text-xl font-extrabold text-pink mb-7 cursor-pointer"
                  onClick={() => setContentType("order-data")}
                >
                  BESTELLUNGEN
                </li>
                <li
                  className="text-xl font-extrabold text-pink cursor-pointer"
                  onClick={() => {
                    window.localStorage.removeItem("pageStore");
                    navigate("/login");
                  }}
                >
                  ABMELDEN
                </li>
              </ul>
            </>
          )}
          {contentType === "personal-data" && (
            <>
              <button
                onClick={() => setContentType("menu")}
                className="px-6 pt-6 pb-2 mb-16"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15.75 19.5L8.25 12l7.5-7.5"
                  />
                </svg>
              </button>
              <header className="px-6 pb-5 text-center">
                <h2 className="text-2xl font-extrabold">PERSÖNLICHE DATEN</h2>
              </header>
              <ul className="px-6 overscroll-contain overflow-y-auto overflow-y-auto pb-10">
                <ProfileChange setContentType={setContentType} />
              </ul>
            </>
          )}
          {contentType === "order-data" && (
            <>
              <button
                onClick={() => setContentType("menu")}
                className="px-6 pt-6 pb-2 mb-16"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15.75 19.5L8.25 12l7.5-7.5"
                  />
                </svg>
              </button>
              <header className="px-6 pb-5 text-center">
                <h2 className="text-2xl font-extrabold">BESTELLUNGEN</h2>
              </header>
              <ul className="overscroll-contain overflow-y-auto overflow-y-auto pb-10">
                <SeeOrders />
              </ul>
            </>
          )}
          {contentType === "lifestyle-score" && (
            <>
              <button
                onClick={() => setContentType("menu")}
                className="px-6 pt-6 pb-2 mb-16"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15.75 19.5L8.25 12l7.5-7.5"
                  />
                </svg>
              </button>
              <header className="px-6 pb-5 text-center">
                <h2 className="text-2xl font-extrabold">LIFESTYLE SCORE</h2>
              </header>
              <ul className="grid grid-cols-2 gap-6 px-6 overscroll-contain overflow-y-auto overflow-y-auto pb-10">
                <div>
                  <Score total={100} score={lifestyleTotal} points />
                  <h3 className="text-center my-4 font-extrabold uppercase text-2xl">
                    Tag 1
                  </h3>
                </div>
                <div>
                  {userChallenge.lifestyleScore2 != "" &&
                  userChallenge.lifestyleScore2 != [] &&
                  userChallenge.lifestyleScore2 != undefined ? (
                    <Score total={100} score={lifestyleTotal2} points />
                  ) : (
                    <Score placeholder />
                  )}

                  <h3
                    className="text-center my-4 font-extrabold uppercase text-2xl "
                    style={{ color: "#000" }}
                  >
                    Tag
                    {differenceInDays(
                      new Date(challenge.endDate),
                      new Date(challenge.startDate)
                    )}
                  </h3>
                </div>
              </ul>
            </>
          )}
        </nav>
        <nav className="hidden flex-col h-full lg:flex">
          <button
            onClick={() => {
              setShowDesktopMenu(false);
              if (showDesktopMenu) {
                setTimeout(() => {
                  setIsSidebarOpen(false);
                }, 175);
              } else {
                setIsSidebarOpen(false);
              }
            }}
            className="px-6 pt-6 pb-2 mb-16"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-6 h-6"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
          <header className="px-6 pb-5 text-center lg:text-left">
            <h2 className="text-2xl font-extrabold">PROFIL</h2>
          </header>
          <ul className="px-6 text-center lg:text-left">
            <li
              className="text-xl font-extrabold mb-7 text-pink cursor-pointer"
              onClick={() => {
                setContentType("personal-data");
                if (!showDesktopMenu) {
                  setShowDesktopMenu(true);
                }
              }}
            >
              PERSÖNLICHE DATEN
            </li>
            <li
              className="text-xl font-extrabold text-pink mb-7 cursor-pointer"
              onClick={() => {
                setContentType("lifestyle-score");
                if (!showDesktopMenu) {
                  setShowDesktopMenu(true);
                }
              }}
            >
              LIFESTYLE SCORE
            </li>
            <li
              className="text-xl font-extrabold text-pink mb-7 cursor-pointer"
              onClick={() => {
                setContentType("order-data");
                if (!showDesktopMenu) {
                  setShowDesktopMenu(true);
                }
              }}
            >
              BESTELLUNGEN
            </li>
            <li
              className="text-xl font-extrabold text-pink cursor-pointer"
              onClick={() => {
                window.localStorage.removeItem("pageStore");
                navigate("/login");
              }}
            >
              ABMELDEN
            </li>
          </ul>
        </nav>
      </div>
      <div
        className={classNames(
          "hidden lg:block lg:fixed lg:w-full lg:max-w-xl lg:h-full lg:bg-[#F4F5EF] lg:z-40 lg:top-0 lg:rounded-tr-lg lg:rounded-br-lg transition-all duration-500 ease-in-out overflow-auto lg:pr-12 lg:pl-14",
          {
            "translate-x-[374px]": showDesktopMenu,
            "-translate-x-[100%]": !showDesktopMenu,
          }
        )}
      >
        <nav className="flex flex-col h-full">
          {contentType === "personal-data" && (
            <>
              <div className="px-6 pt-8 pb-2 mb-20"></div>
              <header className="px-6 pb-5 text-center">
                <h2 className="text-2xl font-extrabold">PERSÖNLICHE DATEN</h2>
              </header>
              <ul className="px-6 overscroll-contain overflow-y-auto overflow-y-auto pb-10">
                <ProfileChange setContentType={setContentType} />
              </ul>
            </>
          )}
          {contentType === "order-data" && (
            <>
              <div className="px-6 pt-8 pb-2 mb-20"></div>
              <header className="px-6 pb-5 text-center">
                <h2 className="text-2xl font-extrabold">BESTELLUNGEN</h2>
              </header>
              <ul className="overscroll-contain overflow-y-auto overflow-y-auto pb-10">
                <SeeOrders />
              </ul>
            </>
          )}
          {contentType === "lifestyle-score" && (
            <>
              <div className="px-6 pt-8 pb-2 mb-20"></div>
              <header className="px-6 pb-5 text-center">
                <h2 className="text-2xl font-extrabold">LIFESTYLE SCORE</h2>
              </header>
              <ul className="grid grid-cols-2 gap-6 px-6 overscroll-contain overflow-y-auto overflow-y-auto pb-10">
                <div>
                  <Score total={100} score={lifestyleTotal} points />
                  <h3 className="text-center my-4 font-extrabold uppercase text-2xl">
                    Tag 1
                  </h3>
                </div>
                <div>
                  {lifestyleScore2 && lifestyleScore2.length > 0 ? (
                    <Score total={100} score={lifestyleTotal2} points />
                  ) : (
                    <Score placeholder />
                  )}
                  <h3
                    className="text-center my-4 font-extrabold uppercase text-2xl"
                    style={{ color: "#000" }}
                  >
                    Tag{" "}
                    {differenceInDays(
                      new Date(challenge.endDate),
                      new Date(challenge.startDate)
                    )}
                  </h3>
                </div>
              </ul>
            </>
          )}
        </nav>
      </div>
    </>
  );
}

export default Sidebar;
