import React from "react";
import classNames from "classnames";
import KeepUpIcon from "../../../../../Icons/KeepUpIcon";


function KeepUpModal({ setOpen }) {
  return (
    <div className="text-center bg-pink">
      <div className="w-8 w-8 mx-auto mb-4">
        <KeepUpIcon />
      </div>
      <h1 className="font-extrabold text-2xl text-white">BLEIB DRAN!</h1>
      <p className="mt-4 text-sm mb-5 text-white">
        Habit nocht nicht oder gar nicht erledigt? Kein Problem! Bei der
        HiLife Challenge geht es nicht um Perfektion sondern Kontinuität.
        <br />
        <br />
        Bleib einfach dran und versuche Dich an folgendes Motto zu halten: NEVER
        MISS TWICE!
        <br />
        <br />
        Du brauchst nochmal ein paar Infos und Tipps rund um die Habits und
        Regeln? Dann schau Dich in unserer Library um:
      </p>

      <button
        className={classNames(
          "text-pink bg-white w-full block font-extrabold p-4 rounded-full transition-colors duration-300 ease-in-out"
        )}
        onClick={() => {
          window.location = "/blog";
        }}
      >
        MEHR INFOS
      </button>

      <button
        className="w-full block text-white font-extrabold text-gray-300 mt-4 pb-8"
        onClick={() => setOpen(false)}
      >
        ABBRECHEN
      </button>
    </div>
  );
}

export default KeepUpModal;
