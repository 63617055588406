import React, { useEffect, useState } from "react";

import "./HeaderNavigation.scss";
import { useStore } from "../../utils/store";
import {
  format,
  differenceInDays,
  endOfYesterday,
  isToday,
  addDays,
  startOfDay,
} from "date-fns";
import { de } from "date-fns/locale";
import ProfileIcon from "../Icons/ProfileIcon";
import HiLifeLogo from "../Icons/HiLifeLogo";
import HiLifeLogo2 from "../Icons/HiLifeLogo2";
import Sidebar from "../Sidebar";
import RulesIcon from "../Icons/RulesIcon";

function HeaderNavigation({
  ranking,
  analyse,
  previousDay,
  setCurrentDay,
  currentDay,
  dashboard,
  setSwitchToggle,
  switchToggle,
  simpleNavi,
  team,
}) {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const [user, setUser] = useStore((state) => [state.user, state.setUser]);
  const challenge = useStore((state) => state.challenge);
  const habits = useStore((state) => state.habits);
  const userChallenge = useStore((state) => state.userChallenge);

  const [maxAllowedDay, setMaxAllowedDay] = useState(currentDay);

  const todaysDate = new Date();

  useEffect(() => {
    const daysSinceStart =
      differenceInDays(todaysDate, new Date(challenge.startDate)) + 1;
    setMaxAllowedDay(daysSinceStart);
  }, [challenge.startDate]);

  const totalDays = differenceInDays(
    new Date(challenge.endDate),
    new Date(challenge.startDate)
  );
  // In HeaderNavigation component
  if (!challenge) {
    return null; // or some loading spinner
  }

  const selectedDate = addDays(new Date(challenge.startDate), currentDay - 1);

  return (
    <>
      <div
        className={`app-navigation bg-zinc-900 lg:!relative lg:!pb-16 ${
          team ? "team-header" : ""
        }`}
      >
        <div className="app-navigation-top">
          <button
            className="app-navigation-avatar"
            onClick={() => setIsSidebarOpen(true)}
          >
            {user.avatar ? <img src={user.avatar} alt="" /> : <ProfileIcon />}
          </button>

          <div className="app-navigation-logo w-20 h-20 mx-auto fill-white">
            {team ? <HiLifeLogo2 /> : <HiLifeLogo />}
          </div>
          <div
            className="app-navigation-burger"
            onClick={() => (window.location = "/blog")}
          >
            <RulesIcon />
          </div>
        </div>

        {!ranking && !dashboard && !simpleNavi && !analyse && (
          <div className="app-navigation-info">
            <p className="app-navigation-date">
              <>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-8 h-8"
                  onClick={() => {
                    if (currentDay > 1) {
                      setCurrentDay((prevDay) => prevDay - 1);
                    }
                  }}
                  style={{
                    pointerEvents: currentDay > 1 ? "auto" : "none",
                    visibility: currentDay > 1 ? "visible" : "hidden",
                  }}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15.75 19.5L8.25 12l7.5-7.5"
                  />
                </svg>
                {format(selectedDate, "EEEE", { locale: de })},{" "}
                {format(selectedDate, "d", { locale: de })}.{" "}
                {format(selectedDate, "MMMM", { locale: de })}{" "}
                {format(selectedDate, "y", { locale: de })}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-8 h-8"
                  onClick={() => {
                    if (currentDay < maxAllowedDay) {
                      setCurrentDay((prevDay) => prevDay + 1);
                    }
                  }}
                  style={{
                    pointerEvents: currentDay < maxAllowedDay ? "auto" : "none",
                    visibility:
                      currentDay < maxAllowedDay ? "visible" : "hidden",
                  }}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M8.25 4.5l7.5 7.5-7.5 7.5"
                  />
                </svg>
              </>
            </p>

            <div className="app-navigation-days">
              <p className="app-navigation-days-text">
                TAG {currentDay} VON {totalDays}
              </p>
            </div>
          </div>
        )}
        {ranking && (
          <div className="app-navigation-ranking lg:max-w-xl lg:mx-auto lg:w-full">
            <div className="app-navigation-ranking-switch">
              <button
                className={`app-navigation-ranking-switch-button ${
                  !switchToggle ? "switch" : ""
                }`}
                onClick={() => setSwitchToggle(false)}
              >
                INDIVIDUAL
              </button>
              <button
                className={`app-navigation-ranking-switch-button ${
                  switchToggle ? "switch" : ""
                }`}
                onClick={() => setSwitchToggle(true)}
              >
                TEAM
              </button>
            </div>
          </div>
        )}

        {analyse && (
          <div className="app-navigation-ranking lg:max-w-xl lg:mx-auto lg:w-full">
            <div className="app-navigation-ranking-switch">
              <button
                className={`app-navigation-ranking-switch-button ${
                  !switchToggle ? "switch" : ""
                }`}
                onClick={() => setSwitchToggle(false)}
              >
                WOCHE
              </button>
              <button
                className={`app-navigation-ranking-switch-button ${
                  switchToggle ? "switch" : ""
                }`}
                onClick={() => setSwitchToggle(true)}
              >
                HABIT
              </button>
            </div>
          </div>
        )}
      </div>

      <Sidebar
        isSidebarOpen={isSidebarOpen}
        setIsSidebarOpen={setIsSidebarOpen}
        user={user}
        setUser={setUser}
        userChallenge={userChallenge}
        challenge={challenge}
      />
    </>
  );
}

export default HeaderNavigation;
