import React, { useEffect, useState } from "react";
import "./TeamDashboard.scss";
import { useStore } from "../../utils/store";
import Score from "../Score";
import axios from "axios";
import { differenceInDays } from "date-fns";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ConfirmationDialog2 from "../ConfirmationDialog2/ConfirmationDialog2";
import { useNavigate } from "react-router-dom";
import AvatarUpload from "../LifestyleSetup/components/LifestyleModals/components/ProfileModal/components/AvatarUpload";
import EditIcon from "../Icons/EditIcon";

function TeamDashboard() {
  const team = useStore((state) => state.teamData);
  const challenge = useStore((state) => state.challenge);
  const setUser = useStore((state) => state.setUser);
  const [settingsVisibleFor, setSettingsVisibleFor] = useState(-1);
  const setTeam = useStore((state) => state.setTeamData);
  const setUserChallenge = useStore((state) => state.setUserChallenge);
  const user = useStore((state) => state.user);
  const userChallenge = useStore((state) => state.userChallenge);
  const [teamScores, setTeamScores] = useState(null);
  const [showDialog, setShowDialog] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const navigate = useNavigate();
  const [teamTotalScore, setTeamTotalScore] = useState(null);
  const [actionType, setActionType] = useState("");
  const [teamRankings, setTeamRankings] = useState([]);
  const [confirmationAction, setConfirmationAction] = useState(null);
  const [confirmationVariables, setConfirmationVariables] = useState({});
  const [confirmationMessage, setConfirmationMessage] = useState("");
  const [userTeam, setUserTeam] = useState(null);
  const [teamUpdate, setTeamUpdate] = useState(false);
  const [avatarSet, setAvatarSet] = useState(true);
  const [avatar, setAvatar] = useState("");
  const [currentDay, setCurrentDay] = useState(
    differenceInDays(
      new Date(
        Date.UTC(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate()
        )
      ),
      new Date(challenge.startDate)
    ) + 1
  );

  useEffect(() => {
    if (!team) {
    }
    if (team && user) {
      setIsAdmin(team?.admins?.includes(user._id));
    }
  }, [team, user]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${
            process.env.NODE_ENV === "production"
              ? "api"
              : "http://localhost:5001/api"
          }/ranking/get/challenge/aggregate/${challenge._id}`
        );

        const aggregatedTeams = response.data.aggregatedTeams;
        if (!Array.isArray(aggregatedTeams) || !aggregatedTeams.length) {
          // If aggregatedTeams is not an array or is an empty array, return early
          console.warn("No teams data available for sorting");
          return;
        }
        // Sort teams by computedTotalScore
        aggregatedTeams.sort((a, b) => {
          const aMaxScore = calculateMaxScore(challenge, a.members.length);
          const bMaxScore = calculateMaxScore(challenge, b.members.length);

          const aPercentage = a.computedTotalScore / aMaxScore;
          const bPercentage = b.computedTotalScore / bMaxScore;

          return bPercentage - aPercentage;
        });

        setTeamRankings(aggregatedTeams);
      } catch (error) {
        console.error("Error fetching team rankings:", error);
      }
    };

    fetchData();
  }, [challenge._id]);

  useEffect(() => {
    const foundUserTeam = teamRankings.find(
      (team) => team.members && team.members.includes(user._id)
    );

    setUserTeam(foundUserTeam);
  }, [teamRankings, user._id]);

  useEffect(() => {
    if (avatar) {
      setTeam({
        ...team,
        avatar: avatar,
      });
      setAvatarSet(true);
    }
  }, [avatar, setTeam]);

  const toggleSettingsVisibility = (index) => {
    setSettingsVisibleFor((prevIndex) => (prevIndex === index ? -1 : index));
  };

  const makeAdmin = async (memberId) => {
    try {
      const apiUrl =
        process.env.NODE_ENV === "production"
          ? "api"
          : "http://localhost:5001/api";

      const response = await axios.post(`${apiUrl}/team/makeAdmin`, {
        memberId,
        teamId: team._id,
      });

      if (response.data.success) {
        toast.success("Member has been made an admin!");

        setTeam({
          ...team,
          admins: [...team.admins, memberId],
        });
        setSettingsVisibleFor(false);
      } else {
        toast.error("Failed to make member an admin!");
      }
    } catch (error) {
      toast.error("Failed to make member an admin!");
      console.error(error);
    }
  };

  const removeFromTeam = async (memberId, isSelfRemoval = false) => {
    try {
      const apiUrl =
        process.env.NODE_ENV === "production"
          ? "api"
          : "http://localhost:5001/api";

      const response = await axios.post(`${apiUrl}/team/removeFromTeam`, {
        memberId,
        teamId: team._id,
      });

      if (response.data.success) {
        toast.success("Member has been removed from the team!");

        const updatedMembers = team.members.filter(
          (m) => m.userId !== memberId
        );
        setTeam({
          ...team,
          members: updatedMembers,
        });
        if (isSelfRemoval) {
          setUserChallenge({
            ...userChallenge,
            team: null,
          });
        }

        // navigate("/challenge-dashboard");
      } else {
        toast.error("Failed to remove member from team!");
      }
    } catch (error) {
      toast.error("Failed to remove member from team!");
      console.error(error);
    }
  };

  const calculateMaxScore = (challenge, memberCount) => {
    const MAX_DAILY_POINTS = 4 + 3 + 3 + 2 + 2 + 2;
    const MAX_WEEKLY_POINTS = 5 + 5 + 2 + 10;
    const currentDate = new Date();
    const challengeStartDate = new Date(challenge.startDate);
    const challengeEndDate = new Date(challenge.endDate);

    // If the current date is after the challenge's end date, then limit the date used for calculations to the end date
    const effectiveDate =
      currentDate > challengeEndDate ? challengeEndDate : currentDate;

    const currentDay = differenceInDays(effectiveDate, challengeStartDate);
    const currentWeek = Math.floor(currentDay / 7); // Only increment the week if a full week has passed

    const maxDailyScore = currentDay * MAX_DAILY_POINTS;
    const maxWeeklyScore = currentWeek * MAX_WEEKLY_POINTS;

    return (maxDailyScore + maxWeeklyScore) * memberCount;
  };

  useEffect(() => {
    if (team && team._id) {
      axios
        .post(
          `${
            process.env.NODE_ENV === "production"
              ? "api"
              : "http://localhost:5001/api"
          }/team/team-scores/${team._id}`,
          { currentDay: currentDay }
        )
        .then((response) => {
          setTeamScores(response.data);
        })
        .catch((error) => {
          console.error("Error fetching team scores:", error);
        });
    }
  }, [team, currentDay]);

  useEffect(() => {
    if (team && team._id) {
      axios
        .get(
          `${
            process.env.NODE_ENV === "production"
              ? "api"
              : "http://localhost:5001/api"
          }/team/team-total-score/${team._id}`
        )
        .then((response) => {
          setTeamTotalScore(response.data.totalScore);
        })
        .catch((error) => {
          console.error("Error fetching team total score:", error);
        });
    }
  }, [team]);

  const maxScore = teamScores
    ? calculateMaxScore(challenge, teamScores.membersData.length)
    : 0;

  const handleConfirmDelete = async () => {
    try {
      await axios.delete(
        `${
          process.env.NODE_ENV === "production"
            ? "api"
            : "http://localhost:5001/api"
        }/team/delete/${team._id}`
      );

      setUserChallenge({
        ...userChallenge,
        team: null,
      });

      toast.success("Team wurde gelöscht.");
      // history.push('/createTeam');
    } catch (error) {
      toast.error("Failed to delete the Team!");
      console.log("Failed to Delete the Team, Error Message", error);
    } finally {
      // Close the dialog after the deletion attempt (either successful or failed)
      setShowDialog(false);
    }
  };

  const handleCancelDelete = () => {
    setShowDialog(false);
  };

  const deleteTeam = () => {
    setConfirmationAction("DELETE_TEAM");
    setActionType("deleteTeam");
    setShowDialog(true);
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(
      function () {
        toast.success("Link copied to clipboard!");
      },
      function (err) {
        toast.error("Failed to copy link!");
      }
    );
  };

  const handleConfirmAction = async () => {
    setShowDialog(false); // Close the dialog
    setActionType("");
    switch (confirmationAction) {
      case "MAKE_ADMIN":
        // Your existing makeAdmin logic goes here, using variables from confirmationVariables
        await makeAdmin(confirmationVariables.memberId);
        break;
      case "REMOVE_FROM_TEAM":
        // Your existing removeFromTeam logic goes here, using variables from confirmationVariables
        await removeFromTeam(
          confirmationVariables.memberId,
          confirmationVariables.isSelfRemoval
        );
        break;
      case "DELETE_TEAM":
        await handleConfirmDelete();
        break;
      // ... Additional cases as necessary
      default:
        console.error(`Unknown confirmation action: ${confirmationAction}`);
    }

    // Reset confirmation state
    setConfirmationAction(null);
    setConfirmationVariables({});
  };

  const handleCancelAction = () => {
    setShowDialog(false); // Close the dialog
    setActionType("");
    // Reset confirmation state
    setConfirmationAction(null);
    setConfirmationVariables({});
  };

  const requestMakeAdmin = (memberId) => {
    setActionType("makeAdmin");
    setConfirmationAction("MAKE_ADMIN");
    setConfirmationVariables({ memberId });
    setShowDialog(true);
  };

  const requestRemoveFromTeam = (memberId) => {
    setActionType("deleteFromTeam");
    setConfirmationAction("REMOVE_FROM_TEAM");
    setConfirmationVariables({ memberId });
    setShowDialog(true);
  };

  const requestLeaveTeam = () => {
    setActionType("leaveTeam");
    setConfirmationAction("REMOVE_FROM_TEAM");
    setConfirmationVariables({ memberId: user._id, isSelfRemoval: true });
    setShowDialog(true);
  };

  const teamIndex =
    teamRankings && userTeam
      ? teamRankings.findIndex((team) => team._id === userTeam._id) + 1
      : null;

  return (
    <div className="teamDashboard">
      <div className="teamDashboard-header">
        {avatarSet ? (
          <div className="teamDashboard-header-avatar">
            <h2>{team.teamName}</h2>
            <img src={team?.avatar} alt="" />
            <div onClick={() => setAvatarSet(false)}>
              <EditIcon />
            </div>
          </div>
        ) : (
          <AvatarUpload
            uploadType="team"
            setAvatar={setAvatar}
            user={user}
            setUser={setUser}
            isEdit={true}
            teamId={team._id}
          />
        )}
        <div className="teamDashboard-header-scores">
          <Score
            title={"Tagesscore"}
            subtitle={`${teamScores ? teamScores.totalDailyScores : 0} von ${
              16 * (teamScores ? teamScores.numberOfMembers : 1)
            } Pkt.`}
            score={teamScores ? teamScores.totalDailyScores : 0}
            total={16 * (teamScores ? teamScores.numberOfMembers : 1)}
          />

          <Score
            classes={
              "sm:px-6 lg:px-0 w-10/12 mw:w-10/12 mxl:w-9/12 lg:w-full mx-auto"
            }
            title={"Ranking"}
            subtitle={teamRankings ? `von ${teamRankings.length}` : ""}
            score={teamIndex}
            total={teamRankings ? teamRankings.length : null}
            place
          />
          <Score
            title={"Gesamtscore"}
            score={teamTotalScore || 0}
            total={maxScore}
          />
        </div>
      </div>

      <div className="teamDashboard-members">
        {teamScores &&
          teamScores.membersData &&
          teamScores.membersData.map((member, index) => (
            <div className="teamDashboard-member" key={index}>
              <img
                src={member.avatar || "default-avatar.png"}
                alt={member.name}
                className="teamDashboard-member-avatar"
              />
              <div className="teamDashboard-member-name">
                <p>{member.name}</p>

                {team && team.admins && team.admins.includes(member.userId) && (
                  <p>Admin</p>
                )}
              </div>

              <div className="teamDashboard-member-percent">
                <p>{((member.dailyScore / 16) * 100).toFixed(0)} %</p>
                <p>heute {member.dailyScore} Pkt</p>
              </div>
              {isAdmin && (
                <div className="teamDashboard-member-admin">
                  <svg
                    width="5"
                    height="18"
                    viewBox="0 0 5 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    onClick={() =>
                      member.userId !== user._id &&
                      toggleSettingsVisibility(index)
                    }
                  >
                    <circle
                      cx="2.147"
                      cy="1.92434"
                      r="1.92434"
                      fill="#1B1B1B"
                    />
                    <circle cx="2.147" cy="9" r="1.92434" fill="#1B1B1B" />
                    <circle
                      cx="2.147"
                      cy="16.0756"
                      r="1.92434"
                      fill="#1B1B1B"
                    />
                  </svg>
                </div>
              )}

              {settingsVisibleFor === index && (
                <div className="teamDashboard-member-admin-settings">
                  <button onClick={() => requestMakeAdmin(member.userId)}>
                    MAKE ADMIN
                  </button>
                  <button onClick={() => requestRemoveFromTeam(member.userId)}>
                    REMOVE FROM TEAM
                  </button>

                  <svg
                    width="5"
                    height="18"
                    viewBox="0 0 5 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    onClick={() => toggleSettingsVisibility(index)} // Toggle on SVG click
                  >
                    <circle cx="2.147" cy="1.92434" r="1.92434" fill="#fff" />
                    <circle cx="2.147" cy="9" r="1.92434" fill="#fff" />
                    <circle cx="2.147" cy="16.0756" r="1.92434" fill="#fff" />
                  </svg>
                </div>
              )}
            </div>
          ))}
      </div>

      {isAdmin && (
        <div className="teamDashboard-invite">
          <h2 className="teamDashboard-invite-title">ZUM TEAM EINLADEN</h2>
          <p className="teamDashboard-invite-text">
            Im Team geht's einfach besser. Lade Deine Freunde in Dein Team ein.
            Kopiere dazu einfach diesen Link und versende ihn per Mail, Whatsapp
            oder SMS.
          </p>
          <personalbar
            className="teamDashboard-invite-link"
            onClick={() =>
              copyToClipboard(
                `${window.location.origin}/teams/${team.teamLink}`
              )
            }
          >
            {window.location.origin}/teams/{team.teamLink}
          </personalbar>
          <button
            className="teamDashboard-invite-button"
            onClick={() =>
              copyToClipboard(
                `${window.location.origin}/teams/${team.teamLink}`
              )
            }
          >
            LINK KOPIEREN
          </button>
        </div>
      )}
      <button
        className="teamDashboard-delete"
        onClick={isAdmin ? deleteTeam : requestLeaveTeam}
        style={{ display: isAdmin || user ? "block" : "none" }}
      >
        {isAdmin ? "DIESES TEAM LÖSCHEN" : "DIESES TEAM VERLASSEN"}
      </button>

      <ConfirmationDialog2
        show={showDialog}
        onConfirm={handleConfirmAction}
        onCancel={handleCancelAction}
        content={actionType}
      />
    </div>
  );
}

export default TeamDashboard;
