import create from "zustand";
import { devtools, persist } from "zustand/middleware";

export const useStore = create(
  devtools(
    persist(
      (set, get) => ({
        version: 1, // Increment this whenever you update the store configuration
        hydrated: false,
        teamData: null,

        user: {},
        challenge: null,
        userChallenge: {},
        currentDay: 0, // Add this line
        points: 0,
        ranking: { userRank: null, totalRankings: null },
        habitSettings: [],
        scores: {}, // Add this line
        setHabitSettings: (habitSettings) => set({ habitSettings }),
        setPoints: (points) => set({ points }),
        setTeamData: (team) => set({ teamData: team }),
        setUser: (user) => {
          set((state) => ({ ...state, user }));
        },
        setChallenge: (challenge) => {
          set((state) => ({ ...state, challenge }));
        },
        setUserChallenge: (userChallenge) => {
          set((state) => {
            const updatedUser = {
              ...get().user,
              challenge: userChallenge,
            };
            return {
              ...state,
              userChallenge,
              user: updatedUser,
            };
          });
        },
        setCurrentDay: (day) => set({ currentDay: day }), // Add this line
        setRanking: (ranking) => set({ ranking }),
        setScores: (scores) => set({ scores }), // Add this line
      }),
      {
        name: "pageStore",
        getStorage: () => localStorage,
        onRehydrateStorage: () => (state) => {
          // Check the version of the rehydrated state
          if (state.version !== 1) {
            // If it's not the current version, clear localStorage and refresh the page
            localStorage.clear();
            window.location.reload();
          }
          state.hydrated = true;
        },
      }
    )
  )
);
