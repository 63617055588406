import React from "react";
import { useNavigate, useLocation } from "react-router-dom"; // Import useLocation and useNavigate
import "./FooterNavigation.scss";

import HomeIcon from "../Icons/HomeIcon";
import RankingIcon from "../Icons/RankingIcon";
import ChatIcon from "../Icons/ChatIcon";
import TeamIcon from "../Icons/TeamIcon";
import AnalyseIcon from "../Icons/AnalyseIcon";
import classNames from "classnames";

const FooterNavigation = React.forwardRef((props, ref) => {
  const navigate = useNavigate();
  const location = useLocation(); // Use the location hook

  return (
    <div className="footer-navigation" ref={ref}>
      <div
        className={classNames("footer-navigation-link", {
          "footer-navigation-link--active": location.pathname === "/home", // Use location.pathname
        })}
        onClick={() => navigate("/home")}
      >
        <HomeIcon />
        <p className="footer-navigation-link-title">Home</p>
      </div>
      <div
        className={classNames("footer-navigation-link", {
          "footer-navigation-link--active": location.pathname === "/analyse", // Use location.pathname
        })}
        onClick={() => navigate("/analyse")}
      >
        <AnalyseIcon />
        <p className="footer-navigation-link-title">Analyse</p>
      </div>
      <div
        className={classNames("footer-navigation-link", {
          "footer-navigation-link--active": location.pathname === "/ranking", // Use location.pathname
        })}
        onClick={() => navigate("/ranking")}
      >
        <RankingIcon />
        <p className="footer-navigation-link-title">Ranking</p>
      </div>

      <div
        className={classNames("footer-navigation-link", {
          "footer-navigation-link--active": location.pathname === "/team", // Use location.pathname
        })}
        onClick={() => navigate("/team")}
      >
        <TeamIcon />
        <p className="footer-navigation-link-title">Team</p>
      </div>

      <div
        className={classNames("footer-navigation-link", {
          "footer-navigation-link--active": location.pathname === "/chat", // Use location.pathname
        })}
        onClick={() => navigate("/chat")}
      >
        <ChatIcon />
        <p className="footer-navigation-link-title">Chat</p>
      </div>
    </div>
  );
});

export default FooterNavigation;
