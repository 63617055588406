import React, { useEffect, useState } from "react";
import "./CreateTeam.scss";
import AvatarUpload from "../LifestyleSetup/components/LifestyleModals/components/ProfileModal/components/AvatarUpload";
import { useStore } from "../../utils/store";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function CreateTeam() {
  const user = useStore((state) => state.user);
  const setUser = useStore((state) => state.setUser);
  const [teamName, setTeamName] = useState("");
  const setTeamData = useStore((state) => state.setTeamData);
  const [avatar, setAvatar] = useState(""); // Assuming AvatarUpload sets this

  const navigate = useNavigate();

  const handleTeamNameChange = (e) => {
    setTeamName(e.target.value);
  };

  const handleCreateTeam = async (e) => {
    e.preventDefault();

    if (!teamName || !avatar) {
      toast.error("Team Name & Profilbild benötigt.");
      return;
    }

    try {
      const response = await axios.post(
        `${
          process.env.NODE_ENV === "production"
            ? "api"
            : "http://localhost:5001/api"
        }/team/create`,
        {
          teamName,
          avatar,
          userId: user._id,
          challengeId: user.challenge.challenge,
          userChallengeId: user.challenge._id,
        }
      );

      if (response.data) {
        toast.success("Team wurde erfolgreich erstellt!");
        setTeamData(response.data.team); // Update the teamData in the store with the newly created team
        setUser((prevUser) => ({ ...prevUser, team: response.data._id }));
        navigate("/team");
      }
    } catch (error) {
      toast.error("Error creating team: " + error.response.data.message);
    }
  };

  return (
    <div className="createTeam">
      <div className="createTeam-container">
        <h2 className="createTeam-container-title">
          DU BIST NOCH IN KEINEM TEAM
        </h2>
        <p className="createTeam-container-text">
          Sprich einen Team-Kapitän an, um einem bereits existierenden Team
          beizutreten. Dieser wird dir einen Einladungslink schicken.
        </p>
      </div>

      {/* <form onSubmit={handleCreateTeam} className="createTeam-form">
        <h1 className="createTeam-title">GRÜNDE EIN EIGENES TEAM</h1>
        {!avatar ? (
          <AvatarUpload
            uploadType="team"
            setAvatar={setAvatar}
            user={user}
            setUser={setUser}
          />
        ) : (
          <img src={avatar} alt="Team Avatar" className="createTeam-avatar" />
        )}

        <label htmlFor="team-name">Team Name</label>
        <input
          id="team-name"
          type="text"
          className="createTeam-input"
          value={teamName}
          onChange={handleTeamNameChange}
        />
        <button type="submit" className="createTeam-button">
          TEAM ERSTELLEN
        </button>
      </form> */}
    </div>
  );
}

export default CreateTeam;
