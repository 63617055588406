import React, { useRef, useState, useEffect } from "react";
import "./ChatView.scss";
import FooterNavigation from "../../Components/FooterNavigation/FooterNavigation";
import HeaderNavigation from "../../Components/HeaderNavigation/HeaderNavigation";

function ChatView() {
  const footerNavigationRef = useRef(null);
  const ownRankRef = useRef(null);
  const [footerNavigationHeight, setFooterNavigationHeight] = useState(0);
  const [ownRankHeight, setOwnRankHeight] = useState(0);

  useEffect(() => {
    setFooterNavigationHeight(footerNavigationRef?.current?.clientHeight);
    setOwnRankHeight(ownRankRef?.current?.clientHeight);
  }, [footerNavigationHeight, ownRankHeight]);

  return (
    <>
      <HeaderNavigation simpleNavi={true} />
      <div
        className="page-container"
        style={{ paddingBottom: footerNavigationHeight + ownRankHeight + "px" }}
      >
        <div className="chat">
          <h1 className="chat-title">CHAT</h1>
          <p className="chat-text">
            Wir kommunizieren über unsere eigens Community App. <br /> <br />
            Noch kein Mitglied? Stelle jetzt eine Beitrittsanfrage!
          </p>
          <a
            className="underline"
            href="https://community.direktplus.net/timeline/challenge"
            target="_blank"
          >
            ZUR HiLife Community App
          </a>
        </div>
      </div>
      <FooterNavigation ref={footerNavigationRef} />
    </>
  );
}

export default ChatView;
