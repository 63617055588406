import React from "react";

import ResetIcon from "../../../../../Icons/ResetIcon";
import axios from "axios";
import classNames from "classnames";
import TeamIcon from "../../../../../Icons/TeamIcon";

function TeamModal({
  setOpen,
  user,
  challenge,
  setUserChallenge,
  userChallenge,
  checked,
}) {
  const deleteFromDatabase = () => {
    axios
      .post(
        `${
          process.env.NODE_ENV === "production"
            ? "api"
            : "http://localhost:5001/api"
        }/register/delete/single/info/string`,
        {
          userId: user._id,
          challengeId: challenge._id,
          path: "fbgroup",
        }
      )
      .then(() => {
        const updatedUserChallenge = {
          ...userChallenge,
          fbgroup: false,
        };

        setUserChallenge(updatedUserChallenge);
        setOpen(false);
      });
  };

  function saveToDatabase() {
    axios
      .post(
        `${
          process.env.NODE_ENV === "production"
            ? "api"
            : "http://localhost:5001/api"
        }/register/add/single/info/string`,
        {
          userId: user._id,
          challengeId: challenge._id,
          path: "fbgroup",
          info: true,
        }
      )
      .then(() => {
        const updatedUserChallenge = {
          ...userChallenge,
          fbgroup: true,
        };

        setUserChallenge(updatedUserChallenge);
        setOpen(false);
      });
  }

  return (
    <div className="w-full">
      {!checked ? (
        <>
          <div className="w-8 w-8 mx-auto mb-4">
            <TeamIcon />
          </div>
          <h1 className="font-extrabold text-2xl text-center">COMMUNITY APP</h1>

          <h5 className="text-sm mt-4 mb-6 font-extrabold text-center">
            Klicke auf den Link und werde Teil unserer Community Chat Gruppe
            eigens Community App.
          </h5>

          <p className="text-sm text-center">
            Damit wir uns während der Challenge schnell und einfach austauschen,
            Rezepte teilen, Fragen stellen und uns gegenseitig motivieren
            können, gibt es eine exklusive geschlossene Gruppe in der Community
            App.
            <br />
            <br />
            <a
              className="underline"
              href="https://community.direktplus.net/timeline/challenge"
              target="_blank"
            >
              ZUR HiLife CHALLENGE COMMUNITY APP
            </a>
            <br />
            <br />
            Übrigens, während der Challenge kommst Du mit einem Klick über den
            unteren Menüpunkt "Chat" in die Gruppe.
            <br /> <br />
            {/* Du hast kein Facebook? Die wichtigsten Infos erhältst Du auch ohne
            die Gruppe in unserem wöchentlichen Newsletter. Trotzdem würden wir
            Dir empfehlen, die Motivation und den Austausch der Gruppe
            mitzunehmen, wenn möglich! */}
          </p>

          <button
            className={classNames(
              "bg-pink w-full block text-white font-extrabold p-4 rounded-full mt-12 transition-colors duration-300 ease-in-out"
            )}
            onClick={() => saveToDatabase()}
          >
            ERLEDIGT
          </button>

          <button
            className="w-full block text-white font-extrabold text-gray-300 mt-4"
            onClick={() => setOpen(false)}
          >
            ABBRECHEN
          </button>
        </>
      ) : (
        <>
          <div className="w-8 w-8 mx-auto mb-4">
            <ResetIcon />
          </div>
          <h1 className="font-extrabold text-2xl text-center">
            EINGABE WIDERRUFEN
          </h1>
          <h3 className="mt-4 text-sm text-center mb-8">
            Klicke auf den Button um deine Eingabe zu widerrufen.
          </h3>

          <button
            className={classNames(
              "bg-pink w-full block text-white font-extrabold p-4 rounded-full transition-colors duration-300 ease-in-out"
            )}
            onClick={() => deleteFromDatabase()}
          >
            EINGABE WIDERRUFEN
          </button>

          <button
            className="w-full block text-white font-extrabold text-gray-300 mt-4"
            onClick={() => setOpen(false)}
          >
            ABBRECHEN
          </button>
        </>
      )}
    </div>
  );
}

export default TeamModal;
