import axios from "axios";
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "./ChallengeSettings.scss";
import LogoUpload from "../LogoUpload/LogoUpload";
import ConfirmationDialog from "../ConfirmationDialog/ConfirmationDialog";

function ChallengeSettings() {
  const [challengeName, setChallengeName] = useState("");
  const [startDate, setStartDate] = useState("");
  const [duration, setDuration] = useState("");
  const [price, setPrice] = useState("");
  const [image, setImage] = useState("");
  const [challenges, setChallenges] = useState([]);
  const [editingChallenge, setEditingChallenge] = useState(null);

  const [challengeToDelete, setChallengeToDelete] = useState(null);
  const [showDeleteConfirmationDialog, setShowDeleteConfirmationDialog] =
    useState(false);

  useEffect(() => {
    fetchChallenges();
  }, []);

  const toggleChallengeActive = async (id) => {
    try {
      await axios.put(
        `${
          process.env.NODE_ENV === "production"
            ? "/api"
            : "http://localhost:5001/api"
        }/challenge/toggle/${id}`
      );
      fetchChallenges();
      toast.success("Challenge status updated successfully!");
    } catch (error) {
      console.error(error);
      toast.error("An error occurred while updating the challenge status.");
    }
  };

  const fetchChallenges = async () => {
    try {
      const response = await axios.get(
        `${
          process.env.NODE_ENV === "production"
            ? "/api"
            : "http://localhost:5001/api"
        }/challenge`
      );
      setChallenges(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    const loadingToastId = toast("Loading...", {
      autoClose: false,
      closeOnClick: false,
    });

    const endDate = new Date(
      new Date(startDate).setDate(new Date(startDate).getDate() + duration * 7)
    );

    const challengeData = new FormData();
    challengeData.append("name", challengeName);
    challengeData.append("startDate", startDate);
    challengeData.append("endDate", endDate);
    challengeData.append("price", price);
    challengeData.append("challengeImage", image);
    challengeData.append("duration", duration);

    try {
      if (editingChallenge) {
        // If we are editing a challenge, send a PUT request
        await axios.put(
          `${
            process.env.NODE_ENV === "production"
              ? "/api"
              : "http://localhost:5001/api"
          }/challenge/${editingChallenge._id}`,
          challengeData
        );
        toast.success("Challenge updated successfully!");
      } else {
        // If we are creating a new challenge, send a POST request
        await axios.post(
          `${
            process.env.NODE_ENV === "production"
              ? "/api"
              : "http://localhost:5001/api"
          }/challenge`,
          challengeData
        );
        toast.success("Challenge created successfully!");
      }
      fetchChallenges();
      resetForm();
    } catch (error) {
      console.error(error);
      toast.error("An error occurred while saving the challenge.");
    } finally {
      // Dismiss the loading toast
      toast.dismiss(loadingToastId);
    }
  };

  const resetForm = () => {
    setChallengeName("");
    setStartDate("");
    setDuration("");
    setPrice("");
    setImage("");
    setEditingChallenge(null);
  };

  const startEditingChallenge = (challenge) => {
    setChallengeName(challenge.name);
    setStartDate(new Date(challenge.startDate).toISOString().slice(0, 10));
    setDuration(challenge.duration);
    setPrice(challenge.price);
    setImage(challenge.image);
    setEditingChallenge(challenge);
  };

  const handleConfirmedDeleteChallenge = async () => {
    try {
      await axios.delete(
        `${
          process.env.NODE_ENV === "production"
            ? "/api"
            : "http://localhost:5001/api"
        }/challenge/${challengeToDelete}`
      );
      fetchChallenges();
      toast.success("Challenge deleted successfully!");
    } catch (error) {
      console.error(error);
      toast.error("An error occurred while deleting the challenge.");
    }
    // Hide the confirmation dialog
    setShowDeleteConfirmationDialog(false);
  };

  const deleteChallenge = (id) => {
    // Set the ID of the challenge to be deleted
    setChallengeToDelete(id);
    // Show the confirmation dialog
    setShowDeleteConfirmationDialog(true);
  };
  const isChallengeActive = (startDate, endDate) => {
    const today = new Date();
    return new Date(startDate) <= today && today <= new Date(endDate);
  };
  const hasActiveChallenge = challenges.some((challenge) =>
    isChallengeActive(challenge.startDate, challenge.endDate)
  );

  const isChallengeOver = (endDate) => {
    const currentDate = new Date();
    const endChallengeDate = new Date(endDate);
    return currentDate > endChallengeDate;
  };
  const isChallengeRunning = (startDate) => {
    const today = new Date();
    return new Date(startDate) <= today;
  };

  const hasRunningChallenge = challenges.some((challenge) =>
    isChallengeRunning(challenge.startDate)
  );

  const isChallengeInFuture = (startDate) => {
    const today = new Date();
    return today < new Date(startDate);
  };

  const hasFutureChallenge = challenges.some((challenge) =>
    isChallengeInFuture(challenge.startDate)
  );

  return (
    <div className="challenge-settings">
      {showDeleteConfirmationDialog && (
        <ConfirmationDialog
          show={showDeleteConfirmationDialog}
          onConfirm={handleConfirmedDeleteChallenge}
          onCancel={() => setShowDeleteConfirmationDialog(false)}
          content={
            "die Challenge, plus alle Challenge bezogenen Daten der Nutzer,"
          }
        />
      )}
      {((!hasActiveChallenge && !hasFutureChallenge) || editingChallenge) && (
        <>
          <h2 className="challenge-settings-title">
            {editingChallenge ? "Edit Challenge" : "Create Challenge"}
          </h2>
          <form className="challenge-settings-form" onSubmit={handleFormSubmit}>
            <label className="challenge-settings-label">
              <p> Challenge Name:</p>
              <input
                className="challenge-settings-input"
                type="text"
                value={challengeName}
                onChange={(e) => setChallengeName(e.target.value)}
              />
            </label>
            <label className="challenge-settings-label">
              <p>Start Datum:</p>
              <input
                className="challenge-settings-input"
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
            </label>
            <label className="challenge-settings-label">
              <p>Dauer (in Wochen):</p>
              <input
                className="challenge-settings-input"
                type="number"
                value={duration}
                onChange={(e) => setDuration(e.target.value)}
              />
            </label>
            <label className="challenge-settings-label">
              <p> Preis:</p>
              <input
                className="challenge-settings-input"
                type="number"
                value={price}
                onChange={(e) => setPrice(e.target.value)}
              />
            </label>
            <div className="challenge-settings-field">
              <label>Challenge Image:</label>
              <LogoUpload image={image} setImage={setImage} preview={image} />
            </div>

            {/* <ImageUpload setImage={setImage} /> */}
            <button className="challenge-settings-button" type="submit">
              {editingChallenge ? "Update Challenge" : "Create Challenge"}
            </button>
            {editingChallenge && (
              <button
                className="challenge-settings-button"
                onClick={() => setEditingChallenge(null)}
              >
                Edit schließen
              </button>
            )}
          </form>
        </>
      )}
      {challenges.length > 0 && !editingChallenge && (
        <div className="challenge-settings-existing">
          <h2 className="challenge-settings-existing-title">
            Erstellte Challenge
          </h2>
          {challenges
            .slice()
            .reverse()
            .map((challenge) => (
              <div
                className="challenge-settings-existing-challenge"
                key={challenge._id}
              >
                {isChallengeOver(challenge.endDate) && (
                  <div className="challenge-over-text"></div>
                )}
                <img
                  src={challenge.image}
                  alt=""
                  className="challenge-settings-existing-image"
                />
                <h3 className="challenge-settings-existing-title">
                  {challenge.name}
                </h3>
                <p>
                  Start Datum:{" "}
                  {new Date(challenge.startDate).toLocaleDateString()}
                </p>
                <p>
                  End Datum: {new Date(challenge.endDate).toLocaleDateString()}
                </p>
                <p>Dauer: {challenge.duration} in Wochen</p>
                <p>Preis: {challenge.price}€</p>
                {!isChallengeOver(challenge.endDate) && (
                  <div className="challenge-settings-existing-buttons">
                    <button
                      className="challenge-settings-existing-button"
                      onClick={() => deleteChallenge(challenge._id)}
                    >
                      Löschen
                    </button>
                    <button
                      className="challenge-settings-existing-button challenge-settings-existing-activate"
                      onClick={() => toggleChallengeActive(challenge._id)}
                    >
                      {challenge.active ? "Deaktivieren" : "Aktivieren"}
                    </button>
                    <button
                      className="challenge-settings-existing-button"
                      onClick={() => startEditingChallenge(challenge)}
                    >
                      {" "}
                      Editieren
                    </button>
                  </div>
                )}
              </div>
            ))}
        </div>
      )}
    </div>
  );
}

export default ChallengeSettings;
