import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useStore } from "../../utils/store";
import { getRemainingTimeUntilMsTimestamp } from "./Utils/CountdownTimer";

const defaultRemainingTime = {
  seconds: "00",
  minutes: "00",
  hours: "00",
  days: "00",
  weeks: "00",
};

const Countdown = ({
  countdownTimestampMs,
  habits,
  weeklie,
  hideText,
  parentClasses,
  numberClasses,
  textClasses,
  dashboard,
}) => {
  const [remainmingTime, setRemainingTime] = useState(defaultRemainingTime);
  const [user, setUser] = useStore((state) => [state.user, state.setUser]);
  const navigate = useNavigate();

  useEffect(() => {
    const intervalId = setInterval(() => {
      updateRemainingTime(countdownTimestampMs);
    }, 1000);
    return () => clearInterval(intervalId);
  }, [
    countdownTimestampMs,
    user.bday,
    user.gender,
    user.avatar,
    user.profileCompleted,
    user.diet,
    user.bookmark,
    user.rules,
    user.lifestyleScore,
    user.fbgroup,
  ]);

  function updateRemainingTime(countdown) {
    setRemainingTime(getRemainingTimeUntilMsTimestamp(countdown));

    if (dashboard) {
      const { seconds, minutes, hours, days } =
        getRemainingTimeUntilMsTimestamp(countdown);
      const totalTime = seconds + minutes + hours + days;

      if (
        totalTime == 0 &&
        user.bday &&
        user.gender &&
        user.avatar &&
        user.profileCompleted &&
        user.diet &&
        user.bookmark &&
        user.rules &&
        user.fbgroup &&
        user.lifestyleScore &&
        user.lifestyleScore.length === 10
      ) {
        navigate("/home");
      }
    }
  }

  return (
    <div className={`countdown grid grid-cols-4 text-center ${parentClasses}`}>
      {!habits && (
        <span
          className={`text-5xl font-extrabold countdown-days-number w-full ${numberClasses}`}
        >
          {remainmingTime.days}
          {weeklie && ":"}
        </span>
      )}
      <span
        className={`text-5xl font-extrabold countdown-hours-number w-full ${numberClasses}`}
      >
        {remainmingTime.hours}
        {(habits || weeklie ? true : false) && ":"}
      </span>
      <span
        className={`text-5xl font-extrabold countdown-minutes-number w-full ${numberClasses}`}
      >
        {remainmingTime.minutes}
        {(habits || weeklie ? true : false) && ":"}
      </span>
      <span
        className={`text-5xl font-extrabold countdown-seconds-number w-full ${numberClasses}`}
      >
        {remainmingTime.seconds}
      </span>
      {!hideText && (
        <>
          <span
            className={`text-sm font-extrabold countdown-days w-full ${textClasses}`}
          >
            Tage
          </span>
          <span
            className={`text-sm font-extrabold countdown-hours w-full ${textClasses}`}
          >
            Std.
          </span>
          <span
            className={`text-sm font-extrabold countdown-minutes w-full ${textClasses}`}
          >
            Min.
          </span>
          <span
            className={`text-sm font-extrabold countdown-seconds w-full ${textClasses}`}
          >
            Sek.
          </span>
        </>
      )}
    </div>
  );
};

export default Countdown;
