import React from "react";
import { SketchPicker } from "react-color";

function ColorPicker({ color, setColor }) {
  const handleChangeComplete = (color) => {
    setColor(color.hex);
  };

  return (
    <div>
      <SketchPicker color={color} onChangeComplete={handleChangeComplete} />
    </div>
  );
}

export default ColorPicker;
