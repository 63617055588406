import axios from "axios";
import classNames from "classnames";
import React, { useState } from "react";
import { useStore } from "../../../../../../utils/store";
import GratitudeIcon from "../../../../../Icons/GratitudeIcon";
import ResetIcon from "../../../../../Icons/ResetIcon";

function GratitudeModal({
  setOpen,
  habit,
  updateHabit,
  userChallenge,
  currentDay,
}) {
  const isHabitChecked = () => {
    const gratitudeLog = userChallenge.dailyHabits["gratitude"].find(
      (log) => log.day === currentDay
    );
    return gratitudeLog && gratitudeLog.points > 0;
  };

  const user = useStore((state) => state.user);
  const [gratitudeLogs, setGratitudeLogs] = useState(["", "", ""]);
  const selectedDate = useStore((state) => state.selectedDate);

  function sendGratitudeToBackend() {
    updateHabit(2, "gratitude", gratitudeLogs);
    setOpen(false);
  }

  function removeGratitudeFromBackend() {
    updateHabit(0, "gratitude", "RESET");
    setOpen(false);
  }

  const handleInputChange = (index, value) => {
    setGratitudeLogs((prevLogs) => {
      const newLogs = [...prevLogs];
      newLogs[index] = value;
      return newLogs;
    });
  };

  return (
    <>
      {!isHabitChecked() ? (
        <div>
          <div className="w-8 w-8 mx-auto mb-4">
            <GratitudeIcon />
          </div>
          <h1 className="font-extrabold text-2xl text-center">DANKBARKEIT</h1>

          <h5 className="text-sm mt-4 mb-6 font-extrabold text-center">
            Wofür bist Du heute dankbar?
          </h5>

          <p className="text-sm text-center">
            Notiere 3 Dinge, für die Du dankbar bist und Du erhältst 2 Punkte.
          </p>

          {gratitudeLogs.map((log, index) => (
            <input
              key={index}
              className="w-full rounded-lg p-4 text-sm shadow-sm border border-slate-300 mt-3"
              name={`gratitude${index + 1}`}
              type="text"
              value={log}
              placeholder="Ich bin dankbar für..."
              onChange={(e) => handleInputChange(index, e.target.value)}
            />
          ))}

          <button
            className={classNames(
              "bg-pink w-full block text-white font-extrabold p-4 rounded-full mt-12 transition-colors duration-300 ease-in-out mb-5"
            )}
            onClick={sendGratitudeToBackend}
          >
            FERTIG / ANALOG
          </button>

          <button
            className="w-full block text-white font-extrabold text-gray-300 mt-4"
            onClick={() => setOpen("keepup")}
          >
            ABBRECHEN
          </button>
        </div>
      ) : (
        <div className="w-full text-center">
          <div className="w-8 w-8 mx-auto mb-4">
            <ResetIcon />
          </div>
          <h1 className="font-extrabold text-2xl">PUNKTE WIDERRUFEN</h1>
          <h3 className="mt-4 text-sm mb-5">
            Hier kannst du deine Punkte zurücksetzen.
          </h3>

          <button
            className={classNames(
              "bg-pink w-full block text-white font-extrabold p-4 rounded-full transition-colors duration-300 ease-in-out"
            )}
            onClick={removeGratitudeFromBackend}
          >
            PUNKTE WIDERRUFEN
          </button>

          <button
            className="w-full block text-white font-extrabold text-gray-300 mt-4 pb-8"
            onClick={() => setOpen(false)}
          >
            ABBRECHEN
          </button>
        </div>
      )}
    </>
  );
}

export default GratitudeModal;
