import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import LogoUpload from "../LogoUpload/LogoUpload";
import ColorPicker from "../ColorPicker/ColorPicker";
import "./HomepageSettings.scss";
import axios from "axios";

function HomepageSettings() {
  const [globalButtonColor, setGlobalButtonColor] = useState("");
  const [globalButtonTextColor, setGlobalButtonTextColor] = useState("");
  const [heroImage, setHeroImage] = useState("");
  const [heroButtonColor, setHeroButtonColor] = useState("");
  const [heroTextColor, setHeroTextColor] = useState("");
  const [textColorModul, setTextColorModul] = useState("");
  const [imageModul, setImageModul] = useState("");
  const [textModul1, setTextModul1] = useState("");
  const [textModul2, setTextModul2] = useState("");
  const [textModul3, setTextModul3] = useState("");
  const [dataExists, setDataExists] = useState(false);
  const [heroText, setHeroText] = useState("");
  const [heroSubline, setHeroSubline] = useState("");
  const [globalButtonText, setGlobalButtonText] = useState("");
  const [footerImage, setFooterImage] = useState("");
  const [footerText, setFooterText] = useState("");
  const [footerTextColor, setFooterTextColor] = useState("");
  const [heroButtonTextColor, setHeroButtonTextColor] = useState("");
  const [logoColor, setLogoColor] = useState("");
  const [loginColor, setLoginColor] = useState("");
  const [habitIconColor, setHabitIconColor] = useState("");
  const [habitBackgroundColor, setHabitBackgroundColor] = useState("");

  useEffect(() => {
    const fetchHomepage = async () => {
      try {
        const res = await axios.get(
          `${
            process.env.NODE_ENV === "production"
              ? "/api"
              : "http://localhost:5001/api"
          }/homepage`
        );
        const homepage = res.data;

        if (homepage) {
          // If there's data, set dataExists to true
          setDataExists(true);

          setGlobalButtonColor(homepage.globalButtonColor || "");
          setGlobalButtonTextColor(homepage.globalButtonTextColor || "");
          setHeroImage(homepage.heroImage || "");
          setHeroButtonColor(homepage.heroButtonColor || "");
          setHeroTextColor(homepage.heroTextColor || "");
          setTextColorModul(homepage.textColorModul || "");
          setImageModul(homepage.imageModul || "");
          setTextModul1(homepage.textModul1 || "");
          setTextModul2(homepage.textModul2 || "");
          setTextModul3(homepage.textModul3 || "");
          setHeroText(homepage.heroText || "");
          setHeroSubline(homepage.heroSubline || "");
          setGlobalButtonText(homepage.globalButtonText || "");
          setFooterImage(homepage.footerImage || "");
          setFooterText(homepage.footerText || "");
          setFooterTextColor(homepage.footerTextColor || "");
          setHeroButtonTextColor(homepage.heroButtonTextColor || "");
          setLogoColor(homepage.logoColor || "");
          setLoginColor(homepage.loginColor || "");
          setHabitIconColor(homepage.habitIconColor || "");
          setHabitBackgroundColor(homepage.habitBackgroundColor || "");
        } else {
          // If there's no data, set dataExists to false
          setDataExists(false);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchHomepage();
  }, []);

  const handleSave = async () => {
    // Show a loading toast and store the toast ID
    const loadingToastId = toast("Loading...", {
      autoClose: false,
      closeOnClick: false,
    });

    const homepageData = new FormData();
    homepageData.append("globalButtonColor", globalButtonColor);
    homepageData.append("globalButtonTextColor", globalButtonTextColor);
    homepageData.append("heroImage", heroImage); // assuming heroImage is a File object
    homepageData.append("heroButtonColor", heroButtonColor);
    homepageData.append("heroTextColor", heroTextColor);
    homepageData.append("textColorModul", textColorModul);
    homepageData.append("imageModul", imageModul); // assuming imageModul is a File object
    homepageData.append("textModul1", textModul1);
    homepageData.append("textModul2", textModul2);
    homepageData.append("textModul3", textModul3);
    homepageData.append("heroText", heroText);
    homepageData.append("heroSubline", heroSubline);
    homepageData.append("globalButtonText", globalButtonText);
    homepageData.append("footerImage", footerImage); // assuming footerImage is a File object
    homepageData.append("footerText", footerText);
    homepageData.append("footerTextColor", footerTextColor);
    homepageData.append("heroButtonTextColor", heroButtonTextColor);
    homepageData.append("logoColor", logoColor);
    homepageData.append("loginColor", loginColor);
    homepageData.append("habitIconColor", habitIconColor);
    homepageData.append("habitBackgroundColor", habitBackgroundColor);

    try {
      if (dataExists) {
        // If the data exists, use a PUT request
        await axios.put(
          `${
            process.env.NODE_ENV === "production"
              ? "/api"
              : "http://localhost:5001/api"
          }/homepage`,
          homepageData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        toast.success("Homepage settings updated successfully!");
      } else {
        // If the data doesn't exist, use a POST request
        await axios.post(
          `${
            process.env.NODE_ENV === "production"
              ? "/api"
              : "http://localhost:5001/api"
          }/homepage`,
          homepageData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        toast.success("Homepage settings created successfully!");
      }
    } catch (error) {
      console.error(error);
      toast.error("An error occurred while saving the homepage settings.");
    } finally {
      // Dismiss the loading toast
      toast.dismiss(loadingToastId);
    }
  };

  return (
    <div className="homepage-settings">
      <h2>Homepage Settings</h2>
      <div className="homepage-settings-field">
        <label>Logo Color:</label>
        <ColorPicker color={logoColor} setColor={setLogoColor} />
      </div>
      <div className="homepage-settings-field">
        <label>Login Color:</label>
        <ColorPicker color={loginColor} setColor={setLoginColor} />
      </div>
      <div className="homepage-settings-field">
        <label>Global Button Text:</label>
        <input
          type="text"
          value={globalButtonText}
          onChange={(e) => setGlobalButtonText(e.target.value)}
        />
      </div>
      <div className="homepage-settings-field">
        <label>Global Button Color:</label>
        <ColorPicker
          color={globalButtonColor}
          setColor={setGlobalButtonColor}
        />
      </div>
      <div className="homepage-settings-field">
        <label>Global Button Text Color:</label>
        <ColorPicker
          color={globalButtonTextColor}
          setColor={setGlobalButtonTextColor}
        />
      </div>
      <div className="homepage-settings-field">
        <label>Hero Image</label>
        <LogoUpload
          image={heroImage}
          setImage={setHeroImage}
          preview={heroImage}
        />
      </div>
      <div className="homepage-settings-field">
        <label>Hero Text:</label>
        <input
          type="text"
          value={heroText}
          onChange={(e) => setHeroText(e.target.value)}
        />
      </div>
      <div className="homepage-settings-field">
        <label>Hero Subline:</label>
        <input
          type="text"
          value={heroSubline}
          onChange={(e) => setHeroSubline(e.target.value)}
        />
      </div>
      <div className="homepage-settings-field">
        <label>Hero Button Color</label>
        <ColorPicker color={heroButtonColor} setColor={setHeroButtonColor} />
      </div>
      <div className="homepage-settings-field">
        <label>Hero Text Color:</label>
        <ColorPicker color={heroTextColor} setColor={setHeroTextColor} />
      </div>
      <div className="homepage-settings-field">
        <label>Hero Button Text Color:</label>
        <ColorPicker
          color={heroButtonTextColor}
          setColor={setHeroButtonTextColor}
        />
      </div>

      <div className="homepage-settings-field">
        <label>Habit Icon Color:</label>
        <ColorPicker color={habitIconColor} setColor={setHabitIconColor} />
      </div>

      <div className="homepage-settings-field">
        <label>Habit Background Color:</label>
        <ColorPicker
          color={habitBackgroundColor}
          setColor={setHabitBackgroundColor}
        />
      </div>
      <div className="homepage-settings-field">
        <label>Text Color Modul</label>
        <ColorPicker color={textColorModul} setColor={setTextColorModul} />
      </div>
      <div className="homepage-settings-field">
        <label>Image Modul</label>
        <LogoUpload
          image={imageModul}
          setImage={setImageModul}
          preview={imageModul}
        />
      </div>
      <div className="homepage-settings-field">
        <label>Text Modul 1</label>
        <input
          type="text"
          value={textModul1}
          onChange={(e) => setTextModul1(e.target.value)}
        />
      </div>
      <div className="homepage-settings-field">
        <label>Text Modul 2</label>
        <input
          type="text"
          value={textModul2}
          onChange={(e) => setTextModul2(e.target.value)}
        />
      </div>
      <div className="homepage-settings-field">
        <label>Text Modul 3</label>
        <input
          type="text"
          value={textModul3}
          onChange={(e) => setTextModul3(e.target.value)}
        />
      </div>
      <div className="homepage-settings-field">
        <label>Footer Image:</label>
        <LogoUpload
          image={footerImage}
          setImage={setFooterImage}
          preview={footerImage}
        />
      </div>
      <div className="homepage-settings-field">
        <label>Footer Text:</label>
        <input
          type="text"
          value={footerText}
          onChange={(e) => setFooterText(e.target.value)}
        />
      </div>
      <div className="homepage-settings-field">
        <label>Footer Text Color:</label>
        <ColorPicker color={footerTextColor} setColor={setFooterTextColor} />
      </div>
      <button className="homepage-settings-button" onClick={handleSave}>
        Save Changes
      </button>
    </div>
  );
}

export default HomepageSettings;
