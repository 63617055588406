import React, { useEffect } from "react";
import "./AfterChallenge.scss";
import HeaderNavigation from "../../Components/HeaderNavigation/HeaderNavigation.js";
import Countdown from "../../Components/Countdown/Countdown";
import LifestyleSetup from "../../Components/LifestyleSetup";
import Footer from "../../Components/Footer/Footer";
import { getTime } from "date-fns";
import { useStore } from "../../utils/store";
import axios from "axios";
import ActionTile from "../ActionTile";
import LifestyleIcon from "../Icons/LifestyleIcon";
import { useState, useRef } from "react";
import LifestyleModals from "../LifestyleSetup/components/LifestyleModals";
import FooterNavigation from "../../Components/FooterNavigation/FooterNavigation.js";
import {
  format,
  differenceInDays,
  endOfYesterday,
  isToday,
  addDays,
} from "date-fns";
import { useCalculatePoints } from "../../Hooks/useCalculatePoints";
import Score from "../Score";
import { useNavigate } from "react-router-dom";

function AfterChallenge() {
  const [modalOpen, setModalOpen] = useState(false);
  const [user, setUser] = useStore((state) => [state.user, state.setUser]);
  const [selectedDate, setSelectedDate] = useStore((state) => [
    state.selectedDate,
    state.setSelectedDate,
  ]);

  const setUserChallenge = useStore((state) => state.setUserChallenge);
  const userChallenge = useStore((state) => state.userChallenge);

  const scores = useStore((state) => state.scores);
  const [challengeStartDate, setChallengeStartdate] = useStore((state) => [
    state.challengeStartDate,
    state.setChallengeStartdate,
  ]);
  const footerNavigationRef = useRef(null);

  const points = useCalculatePoints(
    new Date(challengeStartDate),
    new Date(selectedDate)
  );

  const [ranking, setRanking] = useStore((state) => [
    state.ranking,
    state.setRanking,
  ]);

  const [lifestyleScore2, setLifestyleScore2] = useState(
    userChallenge.lifestyleScore2
  );
  const [lifestyleTotal2, setLifestyleTotal2] = useState(0);

  const [lifestyleScore, setLifestyleScore] = useState(
    userChallenge.lifestyleScore
  );
  const [lifestyleTotal, setLifestyleTotal] = useState(0);

  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get(
        `${
          process.env.NODE_ENV === "production"
            ? "api"
            : "http://localhost:5001/api"
        }/ranking/get/user/rank/${user._id}?challengeId=${
          userChallenge.challenge
        }`
      )
      .then((response) => {
        useStore.getState().setRanking(response.data);
      });
  }, []);

  useEffect(() => {
    if (
      differenceInDays(new Date(selectedDate), new Date(challengeStartDate)) +
        1 <
      29
    ) {
      navigate("/home");
    }

    // axios
    //   .post(
    //     `${
    //       process.env.NODE_ENV === "production"
    //         ? "api"
    //         : "http://localhost:5001/api"
    //     }/challenge/get`
    //   )
    //   .then((challenge) => {
    //     const res = { ...challenge.data.challenge };
    //     setChallengeStartdate(res[0].challengeDate);
    //   })
    //   .catch((err) => {});

    if (userChallenge.lifestyleScore2) {
      let sum = 0;
      userChallenge.lifestyleScore2.forEach((e) => {
        sum = sum + e;
      });
      setLifestyleTotal2(sum);
    }

    if (userChallenge.lifestyleScore) {
      let sum = 0;
      userChallenge.lifestyleScore.forEach((e) => {
        sum = sum + e;
      });
      setLifestyleTotal(sum);
    }
  }, [userChallenge.lifestyleScore2]);

  return (
    <>
      <div
        className="page-container"
        style={{
          paddingBottom: footerNavigationRef?.current?.clientHeight + "px",
        }}
      >
        {" "}
        <div id="confetti-wrapper">
          <div class="confetti"></div>
          <div class="confetti"></div>
          <div class="confetti"></div>
          <div class="confetti"></div>
          <div class="confetti"></div>
          <div class="confetti"></div>
          <div class="confetti"></div>
          <div class="confetti"></div>
          <div class="confetti"></div>
          <div class="confetti"></div>
          <div class="confetti"></div>
          <div class="confetti"></div>
          <div class="confetti"></div>
        </div>
        <HeaderNavigation selectedDate={selectedDate} dashboard />
        <main className="after-challenge dashboard px-4 pt-8 pb-7">
          {userChallenge.lifestyleScore2 != "" &&
          userChallenge.lifestyleScore2 != [] &&
          userChallenge.lifestyleScore2 != undefined ? (
            <div>
              {" "}
              <h1 className="after-challenge-youdidit">YOU DID IT!</h1>
              <div className="relative grid grid-cols-2 gap-4 lg:grid-cols-3 lg:max-w-screen-md lg:mx-auto lg:gap-16 lg:-mt-16 lg:z-20 after-challenge-scores">
                <Score
                  classes={
                    "sm:px-6 lg:px-0 w-10/12 mw:w-10/12 mxl:w-9/12 lg:w-full mx-auto"
                  }
                  title={"Gesamtscore"}
                  score={scores.totalScore}
                  total={scores.maxScore}
                  entireTotalScore
                  mobileHighlight
                />

                <div className="after-challenge-lifestyle-sub-desktop">
                  <Score
                    classes={
                      "col-start-1 col-end-3 w-6/12 mxl:w-7/12 lg:w-full mx-auto lg:col-start-3 desktop-score"
                    }
                    total={100}
                    score={lifestyleTotal2}
                    points
                  />
                  <h2>LIFESTYLE SCORE</h2>
                  <p className="sub-desktop-text">
                    Du hast dich um{" "}
                    {Math.abs(
                      Math.round((lifestyleTotal / lifestyleTotal2 - 1) * 100)
                    )}
                    % verbessert
                  </p>
                </div>

                {scores.ranking && (
                  <Score
                    classes={
                      "sm:px-6 lg:px-0 w-10/12 mw:w-10/12 mxl:w-9/12 lg:w-full mx-auto"
                    }
                    title={"Ranking"}
                    subtitle={`von ${scores.ranking.totalRankings}`}
                    score={scores.ranking.userRank}
                    total={scores.ranking.totalRankings}
                    place
                  />
                )}

                <Score
                  classes={
                    "col-start-1 col-end-3 w-6/12 mxl:w-7/12 lg:w-full mx-auto lg:col-start-3 mobile-score"
                  }
                  total={100}
                  score={lifestyleTotal2}
                  points
                />

                <div className="after-challenge-lifestyle-sub">
                  <h2>LIFESTYLE SCORE</h2>
                  <p>
                    Du hast dich um{" "}
                    {Math.abs(
                      Math.round((lifestyleTotal / lifestyleTotal2 - 1) * 100)
                    )}
                    % verbessert
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <div>
              <h1 className="after-challenge-title">
                Die Challenge ist vorbei!
              </h1>
              <p className="after-challenge-text">
                Bitte führe nochmal den Lifestyle-Check durch.
              </p>
              <div className="after-challenge-lifestyle">
                <ActionTile
                  icon={<LifestyleIcon />}
                  label={"Lifestyle Check"}
                  onClick={() => setModalOpen("lifestyle2")}
                  checked={userChallenge?.lifestyleScore2?.length === 10}
                  classes={"max-w-[30.33%] lg:max-w-[14.66%] w-full flex-auto"}
                  smallIcon
                />
                <LifestyleModals
                  open={modalOpen}
                  setOpen={setModalOpen}
                  user={user}
                  setUser={setUser}
                  userChallenge={userChallenge}
                  setUserChallenge={setUserChallenge}
                />
              </div>
            </div>
          )}

          <Footer />

          {/* <div className="footer-after-navigation">
            {" "}
            <FooterNavigation ref={footerNavigationRef} />
          </div> */}
        </main>
      </div>
    </>
  );
}

export default AfterChallenge;
