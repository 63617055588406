import classNames from "classnames";
import React from "react";
import NourishIcon from "../../../../../Icons/NourishIcon";
import ResetIcon from "../../../../../Icons/ResetIcon";

function NourishModal({
  setOpen,
  habit,
  updateHabit,
  userChallenge,
  currentDay,
}) {
  const isHabitChecked = () => {
    return (
      userChallenge.dailyHabits["nourish"] &&
      userChallenge.dailyHabits["nourish"][currentDay] &&
      userChallenge.dailyHabits["nourish"][currentDay] > 0
    );
  };

  return (
    <>
      {!isHabitChecked() ? (
        <div>
          <div className="w-8 w-8 mx-auto mb-4">
            <NourishIcon />
          </div>
          <h1 className="font-extrabold text-2xl text-center">ERNÄHRUNG</h1>

          <h5 className="text-sm mt-4 mb-6 font-extrabold text-center">
            Hast du dich heute clean ernährt?
          </h5>

          <p className="text-sm text-center">
            Wenn du dich an alle Ernährungsregeln gehalten hast, bekommst du 4
            Punkte.
            <br />
            <br />
            Hast du heute maximal 2 nicht-regelkonforme Lebensmittel oder
            Getränke verzehrt, klicke auf „FAST“ und du erhältst 2 Punkte.
          </p>

          <button
            className={classNames(
              "bg-pink w-full block text-white font-extrabold p-4 rounded-full mt-12 transition-colors duration-300 ease-in-out mb-5"
            )}
            onClick={() => {
              updateHabit(4);
              setOpen(false);
            }}
          >
            FERTIG
          </button>

          <button
            className="bg-gray-300 text-white w-full block text-white font-extrabold p-4 rounded-full transition-colors duration-300 ease-in-out"
            onClick={() => {
              updateHabit(2);
              setOpen(false);
            }}
          >
            FAST
          </button>

          <button
            className="w-full block text-white font-extrabold text-gray-300 mt-4"
            onClick={() => setOpen("keepup")}
          >
            ABBRECHEN
          </button>
        </div>
      ) : (
        <div className="w-full text-center">
          <div className="w-8 w-8 mx-auto mb-4">
            <ResetIcon />
          </div>
          <h1 className="font-extrabold text-2xl">PUNKTE WIDERRUFEN</h1>
          <h3 className="mt-4 text-sm mb-5">
            Hier kannst du deine Punkte zurücksetzen.
          </h3>

          <button
            className={classNames(
              "bg-pink w-full block text-white font-extrabold p-4 rounded-full transition-colors duration-300 ease-in-out"
            )}
            onClick={() => {
              updateHabit(0);
              setOpen(false);
            }}
          >
            PUNKTE WIDERRUFEN
          </button>

          <button
            className="w-full block text-white font-extrabold text-gray-300 mt-4 pb-8"
            onClick={() => setOpen(false)}
          >
            ABBRECHEN
          </button>
        </div>
      )}
    </>
  );
}

export default NourishModal;
