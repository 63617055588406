import React, { useState } from "react";
import { getTime, endOfDay } from "date-fns";

import "./styles/dailies.scss";
import DailiesModals from "./components/DailiesModal";
import TrainingIcon from "../Icons/TrainingIcon";
import NourishIcon from "../Icons/NourishIcon";
import ActionTile from "../ActionTile";
import ShowerIcon from "../Icons/ShowerIcon";
import SleepIcon from "../Icons/SleepIcon";
import BreathIcon from "../Icons/BreathIcon";
import GratitudeIcon from "../Icons/GratitudeIcon";
import Countdown from "../Countdown/Countdown";

function Dailies({ updateHabit, currentDay, userChallenge }) {
  const [modalOpen, setModalOpen] = useState(false);

  const isHabitChecked = (habit) => {
    if (habit === "gratitude") {
      const gratitudeLog = userChallenge.dailyHabits[habit].find(
        (log) => log.day === currentDay
      );
      return gratitudeLog && gratitudeLog.points > 0;
    } else {
      return (
        userChallenge.dailyHabits[habit] &&
        userChallenge.dailyHabits[habit][currentDay] &&
        userChallenge.dailyHabits[habit][currentDay] > 0
      );
    }
  };

  return (
    <div className="mt-5">
      <h2 className="dailies-title text-2xl font-extrabold text-center mb-1">
        HABIT TRACKING
      </h2>
      <div className="dailies-action mb-3">
        <div className="dailies-timer">
          <span className="text-sm">
            <Countdown
              countdownTimestampMs={getTime(endOfDay(new Date()))}
              habits
              hideText
            />
          </span>
          <p className="dailies-timer-text">TIME TO TRACK YOUR</p>
          <p className="dailies-timer-title">DAILIES</p>
        </div>
      </div>

      <div className="flex flex-wrap justify-center gap-4 lg:max-w-2xl lg:mx-auto">
        <ActionTile
          icon={<NourishIcon />}
          onClick={() => setModalOpen("nourish")}
          checked={isHabitChecked("nourish")}
          classes={"max-w-[28%] lg:max-w-[14.66%] w-full flex-auto"}
        />
        <ActionTile
          icon={<TrainingIcon />}
          onClick={() => setModalOpen("training")}
          checked={isHabitChecked("training")}
          classes={"max-w-[28%] lg:max-w-[14.66%] w-full flex-auto"}
        />
        <ActionTile
          icon={<SleepIcon />}
          onClick={() => setModalOpen("sleep")}
          checked={isHabitChecked("sleep")}
          classes={"max-w-[28%] lg:max-w-[14.66%] w-full flex-auto"}
        />
        <ActionTile
          icon={<ShowerIcon />}
          onClick={() => setModalOpen("shower")}
          checked={isHabitChecked("shower")}
          classes={"max-w-[28%] lg:max-w-[14.66%] w-full flex-auto"}
        />
        <ActionTile
          icon={<BreathIcon />}
          onClick={() => setModalOpen("breath")}
          checked={isHabitChecked("breathing")}
          classes={"max-w-[28%] lg:max-w-[14.66%] w-full flex-auto"}
        />
        <ActionTile
          icon={<GratitudeIcon />}
          onClick={() => setModalOpen("gratitude")}
          checked={isHabitChecked("gratitude")}
          classes={"max-w-[28%] lg:max-w-[14.66%] w-full flex-auto"}
        />
      </div>

      <DailiesModals
        open={modalOpen}
        setOpen={setModalOpen}
        currentDay={currentDay}
        updateHabit={updateHabit}
        userChallenge={userChallenge}
      />
    </div>
  );
}

export default Dailies;
