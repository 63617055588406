import React from "react";
import "./styles/action-tile.scss";
import classNames from "classnames";
import CheckIcon from "../Icons/CheckIcon";

function ActionTile({
  onClick,
  classes,
  label,
  icon,
  checked,
  smallIcon,
  disabled,
  nature,
}) {
  return (
    <button
      className={classNames(
        `action-tile relative overflow-hidden aspect-[2/1.25] ${classes}`,
        {
          "action-tile--checked": checked,
          "action-tile--disabled": disabled,
        }
      )}
      onClick={onClick}
    >
      {!!checked && <CheckIcon />}
      <span
        className={classNames("action-tile-icon", {
          "action-tile-icon-small": smallIcon,
        })}
      >
        {icon}
      </span>
      {label && <span className="action-tile-label">{label}</span>}
      {nature > 0 && (
        <span
          style={{
            width: `${Math.floor(
              ((nature > 120 ? 120 : nature) / 120) * 100
            )}%`,
          }}
          className={`absolute bg-black h-4 flex items-center justify-end px-2 bottom-0 left-0 min-w-fit`}
        >
          <span className="font-extrabold text-white">{nature}</span>
        </span>
      )}
    </button>
  );
}

export default ActionTile;
