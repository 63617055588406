import axios from "axios";
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "./HabitSettings.scss";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // import the styles

function HabitSettings() {
  const [challenges, setChallenges] = useState([]);
  const [editingHabit, setEditingHabit] = useState(null);
  const [habitSettings, setHabitSettings] = useState([]);

  useEffect(() => {
    fetchChallenges();
  }, []);

  const fetchChallenges = async () => {
    try {
      const response = await axios.get(
        `${
          process.env.NODE_ENV === "production"
            ? "/api"
            : "http://localhost:5001/api"
        }/challenge`
      );
      setChallenges(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleInputChange = (weekIndex, field, value, subfield = null) => {
    // Create a deep copy of habitSettings
    const newHabitSettings = JSON.parse(JSON.stringify(habitSettings));

    if (subfield !== null) {
      if (Array.isArray(newHabitSettings[weekIndex][field])) {
        // If the field is an array (selfReflection), update the specific question
        newHabitSettings[weekIndex][field][subfield] = value;
      } else {
        // If the field is an object (zoomLink, action), update the specific subfield
        newHabitSettings[weekIndex][field] = {
          ...newHabitSettings[weekIndex][field],
          [subfield]: value,
        };
      }
    } else {
      newHabitSettings[weekIndex][field] = value;
    }
    setHabitSettings(newHabitSettings);
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    // Show a loading toast and store the toast ID
    const loadingToastId = toast("Loading...", {
      autoClose: false,
      closeOnClick: false,
    });

    try {
      if (editingHabit._id) {
        // If the habit settings already exist, update them
        await axios.put(
          `${
            process.env.NODE_ENV === "production"
              ? "/api"
              : "http://localhost:5001/api"
          }/habit/${editingHabit.challengeId}`,
          { weeks: habitSettings }
        );
        toast.success("Habit settings updated successfully!");
      } else {
        // If the habit settings don't exist, create them
        await axios.post(
          `${
            process.env.NODE_ENV === "production"
              ? "/api"
              : "http://localhost:5001/api"
          }/habit/${editingHabit.challengeId}`,
          { weeks: habitSettings }
        );
        toast.success("Habit settings created successfully!");
      }
      fetchChallenges();
    } catch (error) {
      console.error(error);
      toast.error("An error occurred while saving the habit settings.");
    } finally {
      // Dismiss the loading toast
      toast.dismiss(loadingToastId);
    }
  };

  const startEditingHabit = async (challenge) => {
    try {
      const response = await axios.get(
        `${
          process.env.NODE_ENV === "production"
            ? "/api"
            : "http://localhost:5001/api"
        }/habit/${challenge._id}`
      );
      let weeks;
      if (response.data && response.data.weeks.length > 0) {
        // If weeks data exists, use it directly
        weeks = response.data.weeks.map((week) => ({
          ...week,
          selfReflection: Array.isArray(week.selfReflection)
            ? week.selfReflection
            : Array(3).fill(""),
        }));
      } else {
        // If weeks data is empty, initialize it based on the challenge duration
        weeks = Array.from({ length: challenge.duration }, () => ({
          selfReflection: Array(3).fill(""),
          zoomLink: { link: "", text: "", afterLink: "", active: false },
          action: { link: "", title: "", text: "" },
        }));
      }
      setEditingHabit({ ...response.data, weeks });
      setHabitSettings(weeks);
    } catch (error) {
      console.error(error);
    }
  };

  const toggleZoomLinkActiveStatus = async (weekIndex, newStatus) => {
    // Update the active status in the state
    handleInputChange(weekIndex, "zoomLink", newStatus, "active");

    try {
      // Send a PUT request to the server to update the habit settings
      await axios.put(
        `${
          process.env.NODE_ENV === "production"
            ? "/api"
            : "http://localhost:5001/api"
        }/habit/${editingHabit.challengeId}`,
        { weeks: habitSettings }
      );
      toast.success("Zoom link status updated successfully!");
    } catch (error) {
      console.error(error);
      toast.error("An error occurred while updating the Zoom link status.");
    }
  };

  return (
    <div className="habit-settings">
      {challenges
        .slice()
        .reverse()
        .map((challenge) => (
          <div className="habit-settings-challenge" key={challenge._id}>
            <h3 className="habit-settings-challenge-title">{challenge.name}</h3>
            <p> {new Date(challenge.startDate).toLocaleDateString()}</p>
            <button onClick={() => startEditingHabit(challenge)}>
              Habits Bearbeiten
            </button>
            {editingHabit && editingHabit.challengeId === challenge._id && (
              <form className="habit-settings-form" onSubmit={handleFormSubmit}>
                {Array(challenge.duration)
                  .fill()
                  .map((_, weekIndex) => (
                    <div key={weekIndex} className="habit-settings-form-week">
                      <h4>Woche {weekIndex + 1}</h4>
                      <label className="habit-settings-form-label">
                        <p>Selbst Reflektion Fragen:</p>
                        {Array(3)
                          .fill()
                          .map((_, questionIndex) => (
                            <input
                              key={questionIndex}
                              type="text"
                              value={
                                habitSettings[weekIndex]?.selfReflection[
                                  questionIndex
                                ] || ""
                              }
                              onChange={(e) =>
                                handleInputChange(
                                  weekIndex,
                                  "selfReflection",
                                  e.target.value,
                                  questionIndex
                                )
                              }
                            />
                          ))}
                      </label>
                      <label className="habit-settings-form-label">
                        <p>Zoom Link:</p>
                        <input
                          type="text"
                          value={habitSettings[weekIndex]?.zoomLink.link || ""}
                          onChange={(e) =>
                            handleInputChange(
                              weekIndex,
                              "zoomLink",
                              e.target.value,
                              "link"
                            )
                          }
                        />
                      </label>
                      <div className="habit-settings-form-label habit-settings-form-rich-text">
                        <p>Zoom Text:</p>
                        <ReactQuill
                          value={habitSettings[weekIndex]?.zoomLink.text || ""}
                          onChange={(value) =>
                            handleInputChange(
                              weekIndex,
                              "zoomLink",
                              value,
                              "text"
                            )
                          }
                        />
                      </div>

                      <label className="habit-settings-form-label">
                        <p> Zoom Link danach:</p>
                        <button
                          className="habit-settings-activate"
                          onClick={() =>
                            toggleZoomLinkActiveStatus(
                              weekIndex,
                              !habitSettings[weekIndex]?.zoomLink.active
                            )
                          }
                        >
                          {habitSettings[weekIndex]?.zoomLink.active
                            ? "DEACTIVATE"
                            : "ACTIVATE"}
                        </button>
                        <input
                          type="text"
                          value={
                            habitSettings[weekIndex]?.zoomLink.afterLink || ""
                          }
                          onChange={(e) =>
                            handleInputChange(
                              weekIndex,
                              "zoomLink",
                              e.target.value,
                              "afterLink"
                            )
                          }
                        />
                      </label>
                      <label className="habit-settings-form-label">
                        <p>Action Link:</p>
                        <input
                          type="text"
                          value={habitSettings[weekIndex]?.action.link || ""}
                          onChange={(e) =>
                            handleInputChange(
                              weekIndex,
                              "action",
                              e.target.value,
                              "link"
                            )
                          }
                        />
                      </label>
                      <label className="habit-settings-form-label">
                        <p>Action Title:</p>
                        <input
                          type="text"
                          value={habitSettings[weekIndex]?.action.title || ""}
                          onChange={(e) =>
                            handleInputChange(
                              weekIndex,
                              "action",
                              e.target.value,
                              "title"
                            )
                          }
                        />
                      </label>

                      <div className="habit-settings-form-label habit-settings-form-rich-text">
                        <p>Action Text:</p>
                        <ReactQuill
                          value={habitSettings[weekIndex]?.action.text || ""}
                          onChange={(value) =>
                            handleInputChange(
                              weekIndex,
                              "action",
                              value,
                              "text"
                            )
                          }
                        />
                      </div>
                    </div>
                  ))}
                <button type="submit">Save Habits</button>
              </form>
            )}
          </div>
        ))}
    </div>
  );
}

export default HabitSettings;
