import React from "react";
import ResetIcon from "../../../../../Icons/ResetIcon";
import BreathIcon from "../../../../../Icons/BreathIcon";
import classNames from "classnames";

function BreathModal({
  setOpen,
  habit,
  updateHabit,
  userChallenge,
  currentDay,
}) {
  const isHabitChecked = () => {
    return (
      userChallenge.dailyHabits["breathing"] &&
      userChallenge.dailyHabits["breathing"][currentDay] &&
      userChallenge.dailyHabits["breathing"][currentDay] > 0
    );
  };

  return (
    <>
      {!isHabitChecked() ? (
        <div>
          <div className="w-8 w-8 mx-auto mb-4">
            <BreathIcon />
          </div>
          <h1 className="font-extrabold text-2xl text-center">ATMEN</h1>

          <h5 className="text-sm mt-4 mb-6 font-extrabold text-center">
            Zeit für 5 Minuten Achtsamkeit
          </h5>

          <p className="text-sm text-center">
            Konzentriere Dich mindestens 5 Minuten nur auf Deine Atmung. Nutze
            dafür die angeleitete Atmung der High Life Challenge App und wähle
            “Los geht’s”.
            <br />
            <br />
            Wenn Du Deine Atmung bereits mit einer anderen Übung/App erledigt
            hast, wähle “Schon erledigt”.
            <br />
            <br />
            Für das Absolvieren einer Atmemübung erhältst Du 2 Punkte.
          </p>

          <button
            className={classNames(
              "bg-pink w-full block text-white font-extrabold p-4 rounded-full mt-12 transition-colors duration-300 ease-in-out mb-5"
            )}
            onClick={() => setOpen("breathing")}
          >
            LOS GEHTS
          </button>

          <button
            className="bg-gray-300 text-white w-full block text-white font-extrabold p-4 rounded-full transition-colors duration-300 ease-in-out"
            onClick={() => {
              updateHabit(2);
              setOpen(false);
            }}
          >
            SCHON ERLEDIGT
          </button>

          <button
            className="w-full block text-white font-extrabold text-gray-300 mt-4"
            onClick={() => setOpen("keepup")}
          >
            ABBRECHEN
          </button>
        </div>
      ) : (
        <div className="w-full text-center">
          <div className="w-8 w-8 mx-auto mb-4">
            <ResetIcon />
          </div>
          <h1 className="font-extrabold text-2xl">PUNKTE WIDERRUFEN</h1>
          <h3 className="mt-4 text-sm mb-5">
            Hier kannst du deine Punkte zurücksetzen.
          </h3>

          <button
            className={classNames(
              "bg-pink w-full block text-white font-extrabold p-4 rounded-full transition-colors duration-300 ease-in-out"
            )}
            onClick={() => {
              updateHabit(0);
              setOpen(false);
            }}
          >
            PUNKTE WIDERRUFEN
          </button>

          <button
            className="w-full block text-white font-extrabold text-gray-300 mt-4 pb-8"
            onClick={() => setOpen(false)}
          >
            ABBRECHEN
          </button>
        </div>
      )}
    </>
  );
}

export default BreathModal;
