import React, { useEffect, useState } from "react";
import "./Knowledge.scss";
import Blog from "../Blog/Blog";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import HiLifeLogo from "../Icons/HiLifeLogo";
import BackIcon from "../Icons/BackIcon";
import axios from "axios"; // import axios

function Knowledge() {
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    axios
      .get(
        `${
          process.env.NODE_ENV === "production"
            ? "api"
            : "http://localhost:5001/api"
        }/blog`
      ) // replace with your API endpoint
      .then((response) => {
        const currentDateTime = new Date();
        const filteredBlogs = response.data.filter(
          (blog) => new Date(blog.releaseDate) <= currentDateTime
        );
        setBlogs(filteredBlogs);
      })
      .catch((error) => console.error(error));
  }, []);

  const categories = blogs.reduce((acc, blog) => {
    if (!acc[blog.category]) {
      acc[blog.category] = [];
    }
    acc[blog.category].push(blog);
    return acc;
  }, {});

  return (
    <div className="knowledge">
      <div className="knowledge-header">
        <HiLifeLogo />
        <h1 className="knowledge-header-title">WISSENSDATENBANK</h1>
      </div>
      <div
        className="pl-8 pt-8"
        onClick={() => (window.location = "/dashboard")}
      >
        <BackIcon />
      </div>
      {Object.entries(categories).map(([category, blogs]) => (
        <div className="knowledge-group" key={category}>
          <h2 className="knowledge-group-title">{category.toUpperCase()}</h2>
          <Swiper
            spaceBetween={120}
            slidesPerView={1.5}
            // centeredSlides
            breakpoints={{
              650: {
                slidesPerView: 2,

                centerInsufficientSlides: true,
                centeredSlides: false,
              },
              850: {
                slidesPerView: 2,
                centerInsufficientSlides: true,
                centeredSlides: false,
              },
              1050: {
                slidesPerView: 4,
                centerInsufficientSlides: true,
                centeredSlides: false,
              },
              1300: {
                slidesPerView: 5,
                centerInsufficientSlides: true,
                centeredSlides: false,
              },
            }}
          >
            {blogs.map((blog) => (
              <SwiperSlide key={blog._id}>
                <Blog
                  image={blog.image}
                  title={blog.title}
                  text={blog.subTitle}
                  link={blog.link}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      ))}
    </div>
  );
}

export default Knowledge;
