import React, { useEffect, useState } from "react";
import { useStore } from "../../utils/store";
import HiLifeLogo from "../Icons/HiLifeLogo";
import "./Admin.scss";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ChallengeSettings from "../ChallengeSettings/ChallengeSettings";
import HabitSettings from "../HabitSettings/HabitSettings";
import HomepageSettings from "../HomepageSettings/HomepageSettings";
import BlogSettings from "../BlogSettings/BlogSettings";

function Admin() {
  const { state, dispatch } = useStore();
  const [activePage, setActivePage] = useState("Initial");

  useEffect(() => {
    // Fetch any global settings here and dispatch to the store
  }, []);

  const renderPage = () => {
    switch (activePage) {
      case "Challenge":
        return <ChallengeSettings />;
      case "Habits":
        return <HabitSettings />;
      case "Homepage":
        return <HomepageSettings />;
      case "Blog":
        return <BlogSettings />; // You will need to create this component
      default:
        return <div>Initial Page</div>;
    }
  };

  return (
    <div className="admin">
      <ToastContainer />
      <HiLifeLogo className="admin-logo" />
      <h1 className="admin-title">Admin Dashboard</h1>
      <div className="admin-navigation">
        <button
          className={`admin-navigation-button ${
            activePage === "Homepage" ? "active" : ""
          }`}
          onClick={() => setActivePage("Homepage")}
        >
          Homepage
        </button>
        <button
          className={`admin-navigation-button ${
            activePage === "Challenge" ? "active" : ""
          }`}
          onClick={() => setActivePage("Challenge")}
        >
          Challenge
        </button>
        <button
          className={`admin-navigation-button ${
            activePage === "Habits" ? "active" : ""
          }`}
          onClick={() => setActivePage("Habits")}
        >
          Habits
        </button>
        <button
          className={`admin-navigation-button ${
            activePage === "Blog" ? "active" : ""
          }`}
          onClick={() => setActivePage("Blog")}
        >
          Blog
        </button>
      </div>
      <div className="admin-content">{renderPage()}</div>
    </div>
  );
}

export default Admin;
