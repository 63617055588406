import React from "react";
import ResetIcon from "../../../../../Icons/ResetIcon";
import axios from "axios";
import RulesIcon from "../../../../../Icons/RulesIcon";
import NourishIcon from "../../../../../Icons/NourishIcon";
import TrainingIcon from "../../../../../Icons/TrainingIcon";
import SleepIcon from "../../../../../Icons/SleepIcon";
import ShowerIcon from "../../../../../Icons/ShowerIcon";
import BreathIcon from "../../../../../Icons/BreathIcon";
import GratitudeIcon from "../../../../../Icons/GratitudeIcon";
import NatureIcon from "../../../../../Icons/NatureIcon";
import ActionIcon from "../../../../../Icons/ActionIcon";
import KnowledgeIcon from "../../../../../Icons/KnowledgeIcon";
import ReflectionIcon from "../../../../../Icons/ReflectionIcon";
import classNames from "classnames";

function RulesModal({
  setOpen,
  user,
  challenge,
  setUserChallenge,
  userChallenge,
  checked,
}) {
  const deleteFromDatabase = () => {
    axios
      .post(
        `${
          process.env.NODE_ENV === "production"
            ? "api"
            : "http://localhost:5001/api"
        }/register/delete/single/info/string`,
        {
          userId: user._id,
          challengeId: challenge._id,
          path: "rules",
        }
      )
      .then(() => {
        const updatedUserChallenge = {
          ...userChallenge,
          rules: false,
        };

        setUserChallenge(updatedUserChallenge);
        setOpen(false);
      });
  };

  function saveToDatabase() {
    axios
      .post(
        `${
          process.env.NODE_ENV === "production"
            ? "api"
            : "http://localhost:5001/api"
        }/register/add/single/info/string`,
        {
          path: "rules",
          info: true,
          challengeId: challenge._id,
          userId: user._id,
        }
      )
      .then(() => {
        const updatedUserChallenge = {
          ...userChallenge,
          rules: true,
        };

        setUserChallenge(updatedUserChallenge);
        setOpen(false);
      });
  }

  return (
    <>
      {!checked ? (
        <div>
          <div className="w-8 w-8 mx-auto mb-4">
            <RulesIcon />
          </div>
          <h1 className="font-extrabold text-2xl text-center">
            REGELWERK LESEN
          </h1>
          <div className="mt-4">
            <p className="text-xs mb-8">
              Hier sind die Regeln der Challenge in Kurzform für Dich
              zusammengefasst. Lies sie Dir gut durch und klicke dann auf
              “Verstanden”. Ausführlichere Infos und Tipps findest Du in unserer
              -
              <a className="underline" href="/blog">
                Library
              </a>
              .
            </p>

            <h4 className="font-extrabold text-xl text-center mb-4">
              PUNKTE SAMMELN
            </h4>
            <ul className="text-xs text-left mb-8 list-disc ml-4">
              <li className="mb-3 leading-relaxed">
                Sammle täglich Punkte für gesunde Habits. Für die verschiedenen
                Habits gibt es unterschiedliche Punkte - je nach Wichtigkeit
                oder Aufwand.
              </li>
              <li className="mb-3 leading-relaxed">
                Es gibt DAILY HABITS, die Du jeden Tag und WEEKLY HABITS, die Du
                zu einem beliebigen Zeitpunkt in der Woche erledigen sollst.
              </li>
              <li className="mb-3 leading-relaxed">
                Hast Du ein Habit erledigt, hake es in Deinem Dashboard ab.
                Deine Punkte erscheinen am Ende des Tages im Leaderboard.
              </li>
              <li className="mb-3 leading-relaxed">
                Du hast einmal vergessen Deine Habits abzuhaken? Kein Problem,
                Du kannst den VORTAG noch editieren.
              </li>
              <li className="mb-3 leading-relaxed">
                Deine gesammelten Punkte der DAILY HABITS werden am Ende eines
                Tages um 24:00 Uhr zu Deinen Gesamtpunkten addiert und ins
                Leaderboard übertragen.
              </li>
              <li className="mb-3 leading-relaxed">
                Deine gesammelten Punkte der WEEKLY HABITS werden am Ende der
                Woche sonntags um 24:00 zu Deinen Gesamtpunkten addiert und ins
                Leaderboard übertragen.
              </li>
            </ul>
            <h4 className="font-extrabold text-xl text-center mb-4">
              DAILY HABITS
            </h4>
            <div className="mb-5">
              <div className="flex gap-2 items-center mb-2">
                <div className="w-8 h-8">
                  <NourishIcon />
                </div>
                <h6 className="rules-content-desc-title font-extrabold">
                  ERNÄHRUNG
                </h6>
              </div>
              <p className="text-xs">
                Halte Dich täglich an Deine Ernährungsregeln. (4 Punkte)
              </p>
            </div>

            <div className="mb-5">
              <div className="flex gap-2 items-center mb-2">
                <div className="w-8 h-8">
                  <TrainingIcon />
                </div>
                <h6 className="rules-content-desc-title font-extrabold">
                  BEWEGUNG
                </h6>
              </div>
              <p className="text-xs">
                Bewege Dich täglich min. 15 min mit Intention. (3 Punkte)
              </p>
            </div>

            <div className="mb-5">
              <div className="flex gap-2 items-center mb-2">
                <div className="w-8 h-8">
                  <SleepIcon />
                </div>
                <h6 className="rules-content-desc-title font-extrabold">
                  SCHLAF
                </h6>
              </div>
              <p className="text-xs">
                Schlafe täglich mindestens 7 Stunden. (3 Punkte)
              </p>
            </div>

            <div className="mb-5">
              <div className="flex gap-2 items-center mb-2">
                <div className="w-8 h-8">
                  <ShowerIcon />
                </div>
                <h6 className="rules-content-desc-title font-extrabold">
                  HITZE & KÄLTE
                </h6>
              </div>
              <p className="text-xs">
                Setze Deinen Körper täglich Hitze oder Kälte aus. (2 Punkte)
              </p>
            </div>

            <div className="mb-5">
              <div className="flex gap-2 items-center mb-2">
                <div className="w-8 h-8">
                  <BreathIcon />
                </div>
                <h6 className="rules-content-desc-title font-extrabold">
                  ATMUNG
                </h6>
              </div>
              <p className="text-xs">
                Konzentriere Dich täglich 5 Minuten lang auf Deine Atmung. (2
                Punkte)
              </p>
            </div>

            <div className="mb-8">
              <div className="flex gap-2 items-center mb-2">
                <div className="w-8 h-8">
                  <GratitudeIcon />
                </div>
                <h6 className="rules-content-desc-title font-extrabold">
                  DANKBARKEIT
                </h6>
              </div>
              <p className="text-xs">
                Notiere täglich 3 Dinge, für die Du dankbar bist. (2 Punkte)
              </p>
            </div>

            <h4 className="font-extrabold text-xl text-center mb-4">
              WEEKLY HABITS
            </h4>

            <div className="mb-5">
              <div className="flex gap-2 items-center mb-2">
                <div className="w-8 h-8">
                  <NatureIcon />
                </div>
                <h6 className="rules-content-desc-title font-extrabold">
                  NATUR
                </h6>
              </div>
              <p className="text-xs">
                Verbringe jede Woche 120 min in der Natur. (5 Punkte)
              </p>
            </div>

            <div className="mb-5">
              <div className="flex gap-2 items-center mb-2">
                <div className="w-8 h-8">
                  <KnowledgeIcon />
                </div>
                <h6 className="rules-content-desc-title font-extrabold">
                  WISSEN
                </h6>
              </div>
              <p className="text-xs">
                Lerne Neues und nimm an den wöchentlichen Workshops teil. (10
                Punkte)
              </p>
            </div>

            <div className="mb-5">
              <div className="flex gap-2 items-center mb-2">
                <div className="w-8 h-8">
                  <ActionIcon />
                </div>
                <h6 className="rules-content-desc-title font-extrabold">
                  ACTION
                </h6>
              </div>
              <p className="text-xs">
                Werde aktiv und stell Dich der wöchentlichen Aufgabe. (5 Punkte)
              </p>
            </div>

            <div className="mb-5">
              <div className="flex gap-2 items-center mb-2">
                <div className="w-8 h-8">
                  <ReflectionIcon />
                </div>
                <h6 className="rules-content-desc-title font-extrabold">
                  SELBSTREFLEKTION
                </h6>
              </div>
              <p className="text-xs">
                Reflektiere Dich und Deine Leben mit 3 wöchentlichen Fragen (2
                Punkte)
              </p>
            </div>
          </div>

          <button
            className={classNames(
              "bg-pink w-full block text-white font-extrabold p-4 rounded-full transition-colors duration-300 ease-in-out"
            )}
            onClick={() => saveToDatabase()}
          >
            GELESEN
          </button>

          <button
            className="w-full block text-white font-extrabold text-gray-300 mt-4 pb-8"
            onClick={() => setOpen("keepup")}
          >
            ABBRECHEN
          </button>
        </div>
      ) : (
        <div className="text-center w-full">
          <div className="w-8 w-8 mx-auto mb-4">
            <ResetIcon />
          </div>
          <h1 className="font-extrabold text-2xl">NOCHMAL LESEN</h1>
          <h3 className="mt-4 text-sm mb-5">
            Klicke auf den Button um die Regeln nochmal zu lesen.
          </h3>

          <button
            className={classNames(
              "bg-pink w-full block text-white font-extrabold p-4 rounded-full transition-colors duration-300 ease-in-out"
            )}
            onClick={() => deleteFromDatabase()}
          >
            NOCHMAL LESEN
          </button>

          <button
            className="w-full block text-white font-extrabold text-gray-300 mt-4 pb-8"
            onClick={() => setOpen(false)}
          >
            ABBRECHEN
          </button>
        </div>
      )}
    </>
  );
}

export default RulesModal;
